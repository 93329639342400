"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const useGeneralHook_1 = require("core/components/useGeneralHook");
const context_1 = require("src/context/context");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const Notifications = (props) => {
    const { toast } = (0, context_1.useToastContext)();
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    (0, useGeneralHook_1.useGeneralHook)({ title: `Erp - ${translate('notification_parameters', 'შეტობინების პარამატრები')}`, loading: props.hook.state.isFetching });
    return ((0, jsx_runtime_1.jsx)("div", { className: 'scroll pr-7 mr-n7 ps ps--active-y hide-scroll', style: { maxHeight: 300, overflowY: 'scroll' }, children: props.hook.state.data.notifications.map(el => {
            return ((0, jsx_runtime_1.jsxs)("div", { className: `d-flex rounded align-items-center p-2 mb-6 justify-content-between${!el.readAt ? ' bg-smoke' : ''}`, children: [(0, jsx_runtime_1.jsxs)("div", { className: 'd-flex align-items-center', children: [(0, jsx_runtime_1.jsx)("div", { className: `symbol symbol-40 mr-5 symbol-light-${el.readAt ? 'success' : 'danger'}`, children: (0, jsx_runtime_1.jsx)("span", { className: 'symbol-label', children: (0, jsx_runtime_1.jsx)("span", { children: (0, jsx_runtime_1.jsx)("i", { className: 'far fa-bell' }) }) }) }), (0, jsx_runtime_1.jsxs)("div", { className: 'd-flex flex-column', onClick: async () => {
                                    props.setHeaderCascadeMenu('');
                                    const res = await props.hook.handleNotificationClick(el, 'headerMenu');
                                    if (res.errors !== null)
                                        res.errors.forEach(el => toast.danger(el.message));
                                }, children: [(0, jsx_runtime_1.jsx)("a", { href: process.env.BASE_URL + el.link, onClick: e => e.preventDefault(), className: 'text-dark text-hover-primary mb-1 font-size-lg', children: (0, jsx_runtime_1.jsx)("span", { children: el.title }) }), (0, jsx_runtime_1.jsx)("span", { className: 'text-muted', children: el.time })] })] }), (0, jsx_runtime_1.jsxs)("div", { className: 'dropdown dropdown-inline', children: [(0, jsx_runtime_1.jsx)("button", { onClick: () => props.hook.handleShowActionMenu(el.id), type: 'button', className: 'btn btn-hover-light-primary btn-icon btn-sm', children: (0, jsx_runtime_1.jsx)("i", { className: `ki ki-bold-more-hor${props.hook.state.loadingItems.find(id => el.id === id) || props.hook.state.isAllReading ? ' spinner' : ''}` }) }), (0, jsx_runtime_1.jsx)("div", { className: `dropdown-menu
                  dropdown-menu-sm
                  notification-unread
                  dropdown-menu-left${props.hook.showActions === el.id ? ' show' : ''}`, children: (0, jsx_runtime_1.jsx)("button", { className: 'dropdown-item btn', onClick: async (e) => {
                                        e.preventDefault();
                                        const res = await props.hook.setUnreadNotification(el, 'headerMenu');
                                        if (res.errors !== null)
                                            res.errors.forEach(el => toast.danger(el.message));
                                    }, children: translate('mark_as_read', 'წაკითხულად მონიშვნა') }) })] })] }, `'notification-' + ${el.id}`));
        }) }));
};
exports.default = Notifications;
