"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformMyFilesFilterParamsToUrl = exports.transformMyFilesParamsToFilterParams = exports.transformUrlToMyFilesFilterParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const helper_1 = require("src/modules/common/helper");
const transformUrlToMyFilesFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        page: values.page,
        perPage: values.perPage,
        keyword: values.keyword,
        createdAtFrom: values.createdAtFrom,
        createdAtTo: values.createdAtTo
    };
};
exports.transformUrlToMyFilesFilterParams = transformUrlToMyFilesFilterParams;
const transformMyFilesParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        createdAtFrom: params.createdAtFrom && (0, helper_1.transformParamsDate)(params.createdAtFrom),
        createdAtTo: params.createdAtTo && (0, helper_1.transformParamsDate)(params.createdAtTo),
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformMyFilesParamsToFilterParams = transformMyFilesParamsToFilterParams;
const transformMyFilesFilterParamsToUrl = (filterParams) => {
    const { page, perPage, keyword, createdAtFrom, createdAtTo } = filterParams;
    return {
        page,
        perPage,
        keyword,
        createdAtFrom,
        createdAtTo
    };
};
exports.transformMyFilesFilterParamsToUrl = transformMyFilesFilterParamsToUrl;
