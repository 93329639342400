"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const ProcurementStatistics = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: props.procurements.length === 0
            ? ((0, jsx_runtime_1.jsx)("div", { className: 'alert alert-warning text-center', children: translate('record_not_found', 'ჩანაწერი ვერ მოიძებნა') }))
            : ((0, jsx_runtime_1.jsxs)("table", { className: 'table', children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "#" }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: translate('company_name', 'კომპანიის სახელი') }), (0, jsx_runtime_1.jsxs)("th", { scope: 'col', children: [translate('of_the_contract', 'კონტრაქტის'), " #"] }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: translate('date_of_registration', 'რეგისტრაციის თარიღი') }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: translate('contract_type', 'კონტრაქტის ტიპი') }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: translate('conditional_numbers', 'პირობითი ნომერი') }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: translate('field_sum', 'ჯამი') })] }) }), (0, jsx_runtime_1.jsx)("tbody", { children: props.procurements.map((el, index) => {
                            return ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { scope: 'row', children: index + 1 }), (0, jsx_runtime_1.jsx)("td", { children: el.companyName }), (0, jsx_runtime_1.jsx)("td", { children: el.contractNumber }), (0, jsx_runtime_1.jsx)("td", { children: el.registrationDate }), (0, jsx_runtime_1.jsx)("td", { children: el.contractType }), (0, jsx_runtime_1.jsx)("td", { children: el.conditionalNumber }), (0, jsx_runtime_1.jsx)("td", { children: el.sumPrice })] }, el.id.toString()));
                        }) })] })) }));
};
exports.default = ProcurementStatistics;
