"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const purchaseSubject_1 = require("../store/purchaseSubject");
const index_1 = require("api/index");
const queryString_1 = require("core/helpers/queryString");
const helper_1 = require("../helper");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useAddOrEditSubject = (props) => {
    const [characteristics, setCharacteristics] = (0, react_1.useState)([]);
    const [state, dispatch] = (0, purchaseSubject_1.usePurchaseSubjectReducer)();
    const [creatingSpecification, setCreatingSpecification] = (0, react_1.useState)(false);
    const [creatingSubject, setCreatingSubject] = (0, react_1.useState)(false);
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const getPurchaseSubject = async (id) => {
        dispatch({ type: 'SEND_PURCHASE_SUBJECT_REQUEST' });
        const purchaseSubjectData = await index_1.PurchaseSubjectsApi.getPurchaseSubject(id);
        dispatch({ type: 'DONE_PURCHASE_SUBJECT_REQUEST', payload: purchaseSubjectData });
    };
    const updatePurchaseSubject = async (id, values) => {
        const params = (0, helper_1.transformPurchaseSubjectParams)(values);
        dispatch({ type: 'SEND_PURCHASE_SUBJECT_UPDATE' });
        const purchaseSubjectData = await index_1.PurchaseSubjectsApi.updatePurchaseSubject(id, params);
        dispatch({ type: 'DONE_PURCHASE_SUBJECT_UPDATE', payload: purchaseSubjectData });
        return purchaseSubjectData;
    };
    const createPurchaseSubject = async (values) => {
        const params = (0, helper_1.transformPurchaseSubjectParams)(values);
        dispatch({ type: 'SEND_PURCHASE_SUBJECT_CREATE' });
        const purchaseSubjectData = await index_1.PurchaseSubjectsApi.createPurchaseSubject(params);
        dispatch({ type: 'DONE_PURCHASE_SUBJECT_CREATE', payload: purchaseSubjectData });
        if (purchaseSubjectData.data !== null) {
            navigate(`/references/purchases/purchase-subjects/${purchaseSubjectData.data.id}/edit`);
        }
        return purchaseSubjectData;
    };
    const handleCreateSpecification = async (value) => {
        setCreatingSpecification(true);
        const specification = await index_1.PurchasesSpecificationsApi.crateInventorySpecification({ specification: value });
        setCreatingSpecification(false);
        return specification;
    };
    const handleCreateSubject = async (value) => {
        setCreatingSubject(true);
        const specification = await index_1.PurchasesInventoryItemsApi.createInventoryItem({ name: value });
        setCreatingSubject(false);
        return specification;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helper_1.transformPurchaseSubjectFilterParamsToUrl)((0, helper_1.transformUrlToPurchaseSubjectFilterParams)(location.search));
        const searchParams = (0, queryString_1.stringify)({ ...params });
        setListingParams(searchParams || '?');
        if (props.id) {
            index_1.PurchaseSubjectsApi.getPurchaseSubjectsCharacteristics(props.id).then(res => {
                setCharacteristics(res.data);
                dispatch({ type: 'DONE_PURCHASE_SUBJECTS_CHARACTERISTICS', payload: res.errors });
            });
            getPurchaseSubject(props.id).then();
        }
    }, [props.id]);
    return {
        characteristics,
        state,
        updatePurchaseSubject,
        createPurchaseSubject,
        handleCreateSpecification,
        creatingSpecification,
        creatingSubject,
        handleCreateSubject,
        getPurchaseSubject,
        closeErrorMessage,
        listingParams
    };
};
exports.default = useAddOrEditSubject;
