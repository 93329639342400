"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFakeSubmissionVerify = void 0;
const index_1 = require("api/index");
const submissionVerifies_1 = require("api/documents/purchases/submissionVerifies");
const useAccountChange_1 = require("src/modules/debuging/hooks/useAccountChange");
const useFakeSubmission_1 = require("./useFakeSubmission");
const react_1 = require("react");
const context_1 = require("src/context/context");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const useFakeSubmissionVerify = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    const [isApproving, setIsApproving] = (0, react_1.useState)(false);
    const submissionHook = (0, useFakeSubmission_1.useFakeSubmission)({ uid: props.uid });
    const purchasesDepartmentAccount = props.auth.state.data.user?.accounts.find(el => el.name === 'შესყიდვების დეპარტამენტის უფროსი' || el.name === 'შესყიდვების სამსახურის უფროსი');
    const budgetAccount = props.auth.state.data.user?.accounts.find(el => el.name === 'ბიუჯეტირების დეპარტამენტის უფროსი' || el.name === 'ბიუჯეტირებისა და ფინანსური ანალიზის სამსახურის უფროსი');
    const headOfPurchasesStructuralUnitDev = translate('head_of_purchases_department', 'შესყიდვების დეპარტამენტის უფროსი');
    const headOfPurchasesStructuralUnitStaging = translate('head_of_purchase_service', 'შესყიდვების სამსახურის უფროსი');
    const headOfBudgetingStructuralUnitDev = translate('head_of_budgeting_department', 'ბიუჯეტირების დეპარტამენტის უფროსი');
    const headOfBudgetingStructuralUnitStaging = translate('head_of_budgeting_and_financial_analysis', 'ბიუჯეტირებისა და ფინანსური ანალიზის სამსახურის უფროსი');
    const useAccountChangeHook = (0, useAccountChange_1.useAccountChange)({ authUser: props.auth.state.data.user });
    const { toast } = (0, context_1.useToastContext)();
    const approveItems = async (loading = true) => {
        if (loading)
            setIsApproving(true);
        const submissionNumber = await submissionHook.changeFakeStatus(false);
        if (submissionNumber === null) {
            if (loading)
                setIsApproving(false);
            return null;
        }
        if (submissionNumber) {
            const emulatedAccount = await useAccountChangeHook.changeUserOrAccount(headOfPurchasesStructuralUnitDev, headOfPurchasesStructuralUnitStaging, purchasesDepartmentAccount);
            const verifySubmissions = await (0, submissionVerifies_1.getSubmissionVerifies)({ filters: { keyword: submissionNumber } });
            if (verifySubmissions.data !== null && verifySubmissions.data.length > 0) {
                const verifySubmission = verifySubmissions.data[0];
                const verifyItems = await index_1.SubmissionVerifiesApi.getSubmissionVerifyItemsV2(verifySubmission.id, {});
                if (verifyItems.data === null || verifyItems.data.length === 0) {
                    return null;
                }
                const itemIds = verifyItems.data.map(el => el.id);
                const submissionVerify = await index_1.SubmissionVerifiesApi.approveItems({ itemIds });
                if (emulatedAccount?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                if (submissionVerify.data === null) {
                    return null;
                }
                await useAccountChangeHook.changeUserOrAccount(headOfBudgetingStructuralUnitDev, headOfBudgetingStructuralUnitStaging, budgetAccount);
                const approvedItems = await index_1.SubmissionVerifiesApi.approveItems({ itemIds });
                if (emulatedAccount?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                if (!approvedItems) {
                    return null;
                }
                await useAccountChangeHook.changeUserOrAccount(headOfPurchasesStructuralUnitDev, headOfPurchasesStructuralUnitStaging, purchasesDepartmentAccount);
                const verifiedSubmission = await index_1.SubmissionVerifiesApi.requestChange(verifySubmission.id);
                if (emulatedAccount?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                if (!verifiedSubmission) {
                    return null;
                }
                if (loading)
                    setIsApproving(false);
                if (verifiedSubmission.data !== null)
                    toast.success(translate('submission_verify_confirm_successfully', 'წარდგინების გადამოწმების დადასტურება განხორციელდა წარმატებით'));
                return approvedItems.data ? { verifySubmissionItems: approvedItems.data } : null;
            }
            else {
                if (loading)
                    setIsApproving(false);
                toast.danger(translate('submission_number_not_found', 'წარდგინების ნომერი არ მოიძებნა'));
            }
        }
        return null;
    };
    return {
        approveItems,
        isApproving
    };
};
exports.useFakeSubmissionVerify = useFakeSubmissionVerify;
