"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const ReadOnlyModeratorBanks = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    return ((0, jsx_runtime_1.jsx)("div", { className: 'card-body', children: props.form.values.mediatorBanks.map(el => {
            return ((0, jsx_runtime_1.jsxs)("ul", { className: 'list-group list-group-flush', children: [(0, jsx_runtime_1.jsxs)("li", { className: 'list-group-item', children: [(0, jsx_runtime_1.jsxs)("strong", { children: [translate('field_foreign_bank_name', 'უცხოური ბანკის დასახელება'), ": "] }), " ", el.title] }), (0, jsx_runtime_1.jsxs)("li", { className: 'list-group-item', children: [(0, jsx_runtime_1.jsxs)("strong", { children: [translate('field_foreign_bank_code', 'უცხოური ბანკის კოდი'), ": "] }), " ", el.code] })] }, el.id));
        }) }));
};
exports.default = ReadOnlyModeratorBanks;
