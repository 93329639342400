"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.determinationValidation = exports.conditionalSubmissionValidation = exports.conditionalSubmissionItemsValidation = void 0;
const types_1 = require("api/documents/purchases/conditional-submissions/types");
const types_2 = require("api/references/purchase-subject/types");
const validates_1 = require("core/helpers/validates");
const parsers_1 = require("api/parsers/parsers");
const conditionalSubmissionItemsValidation = (values, translate) => {
    const formErrors = {
        conditionalSubmissionItemsErrors: values.conditionalSubmissionItems.map(item => {
            return {
                characteristics: !item.purchaseSubject.specificationName ? (0, validates_1.required)(item.characteristics, translate) : undefined,
                dimensionUnit: (0, validates_1.required)(item.dimensionUnit, translate),
                quantity: (0, validates_1.requiredPositiveNumber)(item.quantity, translate),
                purchaseSubjectId: (0, validates_1.required)(item.inventoryConfigurationId, translate),
                assignment: (0, validates_1.required)(item.assignment, translate),
                project: (0, validates_1.required)(item.project, translate),
                bookAuthor: item.inventoryConfigurationType === types_2.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_2.InventoryConfigurationType.NATIONWIDE ? (0, validates_1.required)(item.bookAuthor, translate) : undefined,
                bookTitle: item.inventoryConfigurationType === types_2.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_2.InventoryConfigurationType.NATIONWIDE ? (0, validates_1.required)(item.bookTitle, translate) : undefined,
                bookLink: item.inventoryConfigurationType === types_2.InventoryConfigurationType.BOOK ? (0, validates_1.required)(item.bookLink, translate) : undefined,
                bookIsbnCode: item.inventoryConfigurationType === types_2.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_2.InventoryConfigurationType.NATIONWIDE ? (0, validates_1.required)(item.bookIsbnCode, translate) : undefined
            };
        })
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (key !== 'conditionalSubmissionItemsErrors' && value)
            errors[key] = value;
        formErrors.conditionalSubmissionItemsErrors = formErrors.conditionalSubmissionItemsErrors?.map(el => {
            if (el && (el.purchaseSubjectId
                || el.project
                || el.quantity
                || el.assignment
                || el.dimensionUnit
                || el.bookLink
                || el.bookAuthor
                || el.bookTitle
                || el.bookIsbnCode
                || el.characteristics)) {
                return el;
            }
            return;
        });
        if (formErrors.conditionalSubmissionItemsErrors.some(err => err)) {
            errors[key] = formErrors.conditionalSubmissionItemsErrors;
        }
    });
    return errors;
};
exports.conditionalSubmissionItemsValidation = conditionalSubmissionItemsValidation;
const requiredAndFiveDaysPast = (date, comment, translate) => {
    if (date === undefined || date === null || date === '')
        return translate('required_field', 'სავალდებულო ველი');
    const givenDate = new Date((0, parsers_1.transformDateParams)(date) || '').getTime();
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    const fiveDaysPast = new Date().setDate(new Date().getDate() + 5);
    if (comment) {
        if (today.getTime() > givenDate || givenDate > fiveDaysPast)
            return translate('please_select_maximum_next_five_days', 'გთხოვთ, აირჩიოთ მაქსიმუმ მომდევნო 5 დღე');
    }
    else {
        if (givenDate < fiveDaysPast)
            return translate('please_select_date_least_five_days_later', 'გთხოვთ, აირჩიოთ მინიმუმ 5 დღის შემდგომი თარიღი');
    }
    return undefined;
};
const conditionalSubmissionValidation = (values, isOriginal, translate) => {
    const formErrors = {
        edocNumber: values.status === types_1.ConditionalSubmissionsStatuses.SENT ? (0, validates_1.required)(values.edocNumber, translate) : undefined,
        requestRegistrationDate: values.status === types_1.ConditionalSubmissionsStatuses.SENT ? (0, validates_1.required)(values.requestRegistrationDate, translate) : undefined,
        author: isOriginal ? (0, validates_1.required)(values.author, translate) : undefined,
        structuralUnit: isOriginal ? (0, validates_1.required)(values.structuralUnit, translate) : undefined,
        termDate: isOriginal
            && (values.status === types_1.ConditionalSubmissionsStatuses.DRAFT || values.status === types_1.ConditionalSubmissionsStatuses.SUSPEND)
            ? requiredAndFiveDaysPast(values.termDate, values.forceMajeureComment, translate) : undefined,
        contactPerson: isOriginal ? (0, validates_1.required)(values.contactPerson, translate) : undefined,
        email: isOriginal ? (0, validates_1.required)(values.email, translate) : undefined,
        phoneNumber: isOriginal ? (0, validates_1.required)(values.phoneNumber, translate) : undefined,
        purpose: isOriginal ? (0, validates_1.sentenceContainsTwoWordsAndLetter)(values.purpose, translate) : undefined,
        filesError: values.status === types_1.ConditionalSubmissionsStatuses.SENT ? (0, validates_1.requiredNotEmptyArray)(values.files, translate) : undefined
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.conditionalSubmissionValidation = conditionalSubmissionValidation;
const determinationValidation = (values, translate) => {
    const formErrors = {
        edocNumber: values.status === types_1.DeterminationsStatuses.SENT ? (0, validates_1.required)(values.edocNumber, translate) : undefined,
        requestRegistrationDate: values.status === types_1.DeterminationsStatuses.SENT ? (0, validates_1.required)(values.requestRegistrationDate, translate) : undefined,
        content: (0, validates_1.sentenceContainsTwoWordsAndLetter)(values.content, translate),
        filesError: values.status === types_1.DeterminationsStatuses.SENT ? (0, validates_1.requiredNotEmptyArray)(values.files, translate) : undefined
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.determinationValidation = determinationValidation;
