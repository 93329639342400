"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.submissionItemsFormValidation = exports.submissionValidation = void 0;
const types_1 = require("api/references/purchase-subject/types");
const validates_1 = require("core/helpers/validates");
const submissionValidation = (values, translate) => {
    const formErrors = {
        number: (0, validates_1.required)(values.number, translate),
        structuralUnit: (0, validates_1.required)(values.structuralUnit, translate),
        employee: (0, validates_1.required)(values.employee, translate),
        date: (0, validates_1.required)(values.date, translate),
        executionDate: (0, validates_1.required)(values.executionDate, translate)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.submissionValidation = submissionValidation;
const submissionItemsFormValidation = (values, translate) => {
    const formErrors = {
        submissionItemsErrors: values.submissionItems.map(item => {
            const dividedItems = values.submissionItems.filter(el => item.id && el.parentId === item.id);
            if (dividedItems.length !== 0)
                dividedItems.push(item);
            const totalQuantity = dividedItems.reduce((accumulator, currentValue) => accumulator + (currentValue.quantity || 0), 0);
            return {
                cpvCode: (0, validates_1.required)(item.cpvCode, translate),
                inventoryConfigurationId: (0, validates_1.required)(item.inventoryConfigurationId, translate),
                dimensionUnit: (0, validates_1.required)(item.dimensionUnit, translate),
                project: (0, validates_1.required)(item.project, translate),
                currency: (0, validates_1.required)(item.currency, translate),
                currencyExchangeRate: item.currency?.label !== 'GEL' ? (0, validates_1.requiredPositiveNumber)(item.currencyExchangeRate, translate) : undefined,
                quantity: !(0, validates_1.requiredPositiveNumber)(item.quantity, translate)
                    ? item.originalQuantity && totalQuantity > item.originalQuantity ? `${translate('sum_items_should_not_exceed', 'საგნების ჯამი არ უნდა აღემატებოდეს')} ${item.originalQuantity}` : undefined
                    : translate('required_positive_number', 'სავალდებულოა დადებითი რიცხვი'),
                price: (0, validates_1.requiredPositiveNumber)(item.price, translate),
                assignment: (0, validates_1.required)(item.assignment, translate),
                characteristics: !item.purchaseSubject.specificationName ? (0, validates_1.required)(item.characteristics, translate) : undefined,
                templateType: (0, validates_1.required)(item.templateType, translate),
                bookAuthor: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? (0, validates_1.required)(item.bookAuthor, translate) : undefined,
                bookTitle: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? (0, validates_1.required)(item.bookTitle, translate) : undefined,
                bookLink: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK ? (0, validates_1.required)(item.bookLink, translate) : undefined,
                bookIsbnCode: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? (0, validates_1.required)(item.bookIsbnCode, translate) : undefined
            };
        })
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (key !== 'submissionItemsErrors' && value)
            errors[key] = value;
        formErrors.submissionItemsErrors = formErrors.submissionItemsErrors?.map(err => {
            if (err && (err.price
                || err.project
                || err.currencyExchangeRate
                || err.assignment
                || err.quantity
                || err.cpvCode
                || err.inventoryConfigurationId
                || err.dimensionUnit
                || err.currency
                || err.templateType
                || err.bookLink
                || err.bookAuthor
                || err.bookTitle
                || err.bookIsbnCode
                || err.characteristics)) {
                return err;
            }
            return;
        });
        if (formErrors.submissionItemsErrors.some(err => err)) {
            errors[key] = formErrors.submissionItemsErrors;
        }
    });
    return errors;
};
exports.submissionItemsFormValidation = submissionItemsFormValidation;
// export const submissionItemFormValidation = (values: SubmissionItemType): SubmissionItemErrors | null => {
//   const formErrors: SubmissionItemErrors = {
//     cpvCode: required(values.cpvCode),
//     inventoryConfigurationId: required(values.inventoryConfigurationId),
//     dimensionUnit: required(values.dimensionUnit),
//     project: required(values.project),
//     currency: required(values.currency),
//     currencyExchangeRate: values.currency?.label !== 'GEL' ? requiredPositiveNumber(values.currencyExchangeRate) : undefined,
//     quantity: requiredPositiveNumber(values.quantity),
//     price: requiredPositiveNumber(values.price),
//     assignment: required(values.assignment),
//     characteristics: !values.purchaseSubject.specificationName ? required(values.characteristics) : undefined,
//     templateType: required(values.templateType)
//   }
//   const errors: SubmissionItemErrors = {} as SubmissionItemErrors
//   Object.entries(formErrors).forEach(([key, value]) => {
//     if (value) errors[key as keyof SubmissionItemErrors] = value
//   })
//   if(objectIsEmpty(errors as Record<string, unknown>)) return null
//   return errors
// }
