"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.procurementItemsValidation = exports.procurementValidation = void 0;
const types_1 = require("api/documents/purchases/procurements/types");
const types_2 = require("api/documents/purchases/procurementCorrections/types");
const validates_1 = require("core/helpers/validates");
const procurementValidation = (values, translate) => {
    if (values === null)
        return {};
    const formErrors = {
        signedDocumentNumber: values.status === types_1.ProcurementStatuses.SIGN_PROGRESS ? (0, validates_1.required)(values.signedDocumentNumber, translate) : undefined,
        dateOfConclusion: values.status === types_1.ProcurementStatuses.SIGN_PROGRESS ? (0, validates_1.required)(values.dateOfConclusion, translate) : undefined,
        deliveryDate: (0, validates_1.required)(values.deliveryDate, translate),
        company: (0, validates_1.required)(values.company, translate),
        companyAddress: (0, validates_1.required)(values.companyAddress, translate),
        companyBankAccount: (0, validates_1.required)(values.companyBankAccount, translate),
        companySignatory: (0, validates_1.required)(values.companySignatory, translate),
        type: (0, validates_1.required)(values.type, translate),
        templateType: (0, validates_1.required)(values.templateType, translate),
        contractValidityDate: (0, validates_1.required)(values.contractValidityDate, translate),
        // itemsError: requiredNotEmptyArray(values.items),
        filesError: values.status === types_1.ProcurementStatuses.SIGN_PROGRESS ? (0, validates_1.requiredNotEmptyArray)(values.files, translate) : undefined,
        attachedFilesError: values.status === types_1.ProcurementStatuses.SIGN_PROGRESS ? (0, validates_1.requiredNotEmptyArray)(values.attachedFiles, translate) : undefined,
        attachedSignedFilesError: values.status === types_1.ProcurementStatuses.SENT_FOR_SIGNATURE ? (0, validates_1.requiredNotEmptyArray)(values.attachedSignedFiles, translate) : undefined,
        procurementFilesError: (values.isOther) || (!values.isOther && values.templateType === types_1.TemplateTypes.CONSOLIDATED) ? (0, validates_1.requiredNotEmptyArray)(values.procurementFile, translate) : undefined,
        legalBasis: values.templateType !== types_1.TemplateTypes.NON_STATE_PROCUREMENT ? (0, validates_1.required)(values.legalBasis, translate) : undefined
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.procurementValidation = procurementValidation;
const procurementItemsValidation = (values, translate, reasons) => {
    const formErrors = {
        procurementItemsErrors: values.procurementItemsValues.map(item => {
            return {
                budgetArticleId: reasons ? reasons?.some(reason => Number(reason.id) === types_2.ReasonTypes.PRICE) ? (0, validates_1.required)(item.budgetArticle, translate) : undefined : (0, validates_1.required)(item.budgetArticle, translate),
                price: reasons ? reasons?.some(reason => Number(reason.id) === types_2.ReasonTypes.PRICE) ? (0, validates_1.requiredPositiveNumber)(item.price, translate) : undefined : (0, validates_1.requiredPositiveNumber)(item.price, translate),
                quantity: reasons?.some(reason => Number(reason.id) === types_2.ReasonTypes.CHANGE_QUANTITY_OF_PURCHASE_SUBJECTS) ? (0, validates_1.required)(item.quantity, translate) : undefined,
                purchaseSubject: reasons?.some(reason => Number(reason.id) === types_2.ReasonTypes.SUBJECT_NAME) ? (0, validates_1.required)(item.purchaseSubject, translate) : undefined,
                cpvCode: reasons?.some(reason => Number(reason.id) === types_2.ReasonTypes.CHANGE_CPV_CODE) ? (0, validates_1.required)(item.cpvCode, translate) : undefined,
                project: reasons?.some(reason => Number(reason.id) === types_2.ReasonTypes.FUNDING_SOURCE) ? (0, validates_1.required)(item.project, translate) : undefined
            };
        })
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (key !== 'procurementItemsErrors' && value)
            errors[key] = value;
        formErrors.procurementItemsErrors = formErrors.procurementItemsErrors?.map(el => {
            if (el && (el.price
                || el.budgetArticleId
                || el.quantity
                || el.purchaseSubject
                || el.cpvCode
                || el.project)) {
                return el;
            }
            return;
        });
        if (formErrors.procurementItemsErrors.some(err => err)) {
            errors[key] = formErrors.procurementItemsErrors;
        }
    });
    return errors;
};
exports.procurementItemsValidation = procurementItemsValidation;
// export const templateValidation = (values: ProcurementTemplateFormValue): ProcurementTemplateFormValidation => {
// 	const formErrors: ProcurementTemplateFormValidation = {
// 		content: required(values.content)
// 	}
// 	const errors: ProcurementTemplateFormValidation = {} as ProcurementTemplateFormValidation
// 	Object.entries(formErrors).forEach(([key, value]) => {
// 		if (value) errors[key as keyof ProcurementTemplateFormValidation] = value
// 	})
// 	return errors
// }
