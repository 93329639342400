"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sentEmailStatusOptions = exports.parseSentEmail = exports.parseSentEmails = void 0;
const types_1 = require("api/administer/sentEmails/types");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseSentEmails = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const sentEmails = response.content;
    try {
        return {
            data: sentEmails.data.map(el => parseSentEmailData(el)),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(sentEmails.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseSentEmails = parseSentEmails;
const parseSentEmail = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const sentEmail = response.content;
    try {
        return {
            data: {
                ...parseSentEmailData(sentEmail.data),
                ccAddresses: sentEmail.data.attributes.ccAddresses.map(el => el),
                body: sentEmail.data.attributes.body,
                attachments: sentEmail.data.relationships.attachments.data.map(attachment => {
                    return {
                        name: attachment.relationships.mediaFile === null
                            ? attachment.attributes.name
                            : attachment.relationships.mediaFile.data.attributes.originalName,
                        uid: attachment.relationships.mediaFile !== null
                            ? attachment.relationships.mediaFile.data.id
                            : null,
                        contentDownloadUrl: attachment.relationships.mediaFile === null
                            ? attachment.attributes.contentDownloadUrl
                            : null
                    };
                })
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseSentEmail = parseSentEmail;
const parseSentEmailData = (data) => {
    return {
        id: data.id,
        status: data.attributes.status,
        visualStatus: parseSentEmailsVisualStatuses(data.attributes.status),
        address: data.attributes.address,
        subject: data.attributes.subject,
        createdAt: (0, parsers_1.transformDate)(data.attributes.createdAt),
        createdDateTimeAt: (0, parsers_1.transformDateTime)(data.attributes.createdAt),
        updatedAt: (0, parsers_1.transformDate)(data.attributes.updatedAt),
        updatedDateTimeAt: (0, parsers_1.transformDateTime)(data.attributes.updatedAt),
        attachmentsQuantity: data.attributes.attachmentsQuantity
    };
};
const parseSentEmailsVisualStatuses = (status) => {
    switch (status) {
        case types_1.SentEmailsStatuses.PROCESSING:
            return { name: 'პროცესში', color: 'warning' };
        case types_1.SentEmailsStatuses.SENT:
            return { name: 'გაგზავნილი', color: 'green' };
        case types_1.SentEmailsStatuses.FAILED:
            return { name: 'წარუმატებელი', color: 'danger' };
    }
};
const sentEmailStatusOptions = (translate) => {
    return [
        { id: String(types_1.SentEmailsStatuses.PROCESSING), label: translate('in_process', 'პროცესში') },
        { id: String(types_1.SentEmailsStatuses.SENT), label: translate('sent', 'გაგზავნილი') },
        { id: String(types_1.SentEmailsStatuses.FAILED), label: translate('unsuccessful', 'წარუმატებელი') }
    ];
};
exports.sentEmailStatusOptions = sentEmailStatusOptions;
