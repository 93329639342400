"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformYear = exports.checkAssignMonthsRange = exports.checkAssignYearRange = exports.transformWorkInfoYearValidity = exports.checkYearValidityForAssignSubmissionVerify = exports.checkYearValidityForAssignSubmission = exports.transformWorkInfoItemErrors = exports.assignSubmissionItemsFormValidation = void 0;
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const types_2 = require("api/references/structuralUnitsUpgraded/types");
const validates_1 = require("core/helpers/validates");
const helper_1 = require("src/modules/common/helper");
// import { transformDate } from '../../../../common/helper'
// export const assignSubmissionFormItemValidation = (values: HRAssignSubmissionFormItem): AssignSubmissionItemFormValidations => {
//   const formErrors: AssignSubmissionItemFormValidations = {
//     personalNumber: values.citizen === Citizen.GEORGIAN ? requiredPersonalNumber(values.personalNumber) : undefined,
//     passportNumber: values.citizen === Citizen.FOREIGN ? required(values.passportNumber) : undefined,
//     firstname: required(values.firstname),
//     lastname: required(values.lastname),
//     phoneNumber: required(values.phoneNumber),
//     positionInfoErrors: values.positionInfos.map(positionInfo => {
//       return {
//         position: required(positionInfo.position),
//         project: required(positionInfo.project),
//         workPlaces: requiredNotEmptyArray(positionInfo.workPlaces),
//         startDate: required(positionInfo.startDate),
//         basicFunctionDuties: required(positionInfo.basicFunctionDuties),
//         workTypesInfoErrors: positionInfo.workInfos.map(workInfo => {
//           return {
//             workload: required(workInfo.workload),
//             salaryType: required(workInfo.salaryType),
//             salaryAmount: workInfo.salaryType && Number(workInfo.salaryType.id) === SalaryType.FIXED ? requiredPositiveNumber(workInfo.salaryAmount) : undefined,
//             salaryGivenType: workInfo.salaryType && Number(workInfo.salaryType.id) === SalaryType.FIXED ? required(workInfo.salaryGivenType) : undefined,
//             workType: workInfo.salaryType && Number(workInfo.salaryType.id) === SalaryType.BY_JOB ? required(workInfo.workType) : undefined,
//             remunerationWork: workInfo.salaryType && Number(workInfo.salaryType.id) === SalaryType.BY_JOB ? required(workInfo.remunerationWork) : undefined,
//             workTypeInfoItems: workInfo.salaryType && Number(workInfo.salaryType.id) !== SalaryType.FIXED
//               ? Number(workInfo.salaryType.id) !== SalaryType.BY_JOB ? transformWorkInfoItemErrors(workInfo, positionInfo.startDate, positionInfo.endDate)
//                 : workInfo.remunerationWork ? transformWorkInfoItemErrors(workInfo, positionInfo.startDate, positionInfo.endDate) : []
//               : []
//           }
//         })
//       }
//     })
//   }
//   const errors: AssignSubmissionItemFormValidations = {} as AssignSubmissionItemFormValidations
//   Object.entries(formErrors).forEach(([key, value]) => {
//     if (value && key !== 'positionInfoErrors') errors[key as keyof AssignSubmissionItemFormValidations] = value
//     if ((formErrors.positionInfoErrors as Array<PositionInfoErrors>).some(
//       positionInfo => positionInfo.project
//       || positionInfo.position
//       || positionInfo.startDate
//       || positionInfo.workPlaces
//       || positionInfo.basicFunctionDuties
//       || positionInfo.workTypesInfoErrors.some(
//         workType => workType.workload
//           || workType.salaryType
//           || workType.salaryAmount
//           || workType.salaryGivenType
//           || workType.workType
//           || workType.remunerationWork
//           || workType.workTypeInfoItems.some(
//             item => item.months
//             || item.dateRange
//             || item.salaryAmount
//             || item.salaryGivenType
//             || item.supplementAmount
//             || item.unitName
//             || item.amount
//             || item.administrativeOrdersArticle
//             || item.tariff
//             || item.volumeWorkPerformed
//             || item.year
//           )
//       )
//     )) {
//       errors[key as keyof AssignSubmissionItemFormValidations] = value
//     }
//   })
//   return errors
// }
const assignSubmissionItemsFormValidation = (values, translate) => {
    const formErrors = {
        assignSubmissionItemsErrors: values.employees.map(item => {
            return {
                personalNumber: item.citizen === types_1.Citizen.GEORGIAN ? (0, validates_1.requiredPersonalNumber)(item.personalNumber, translate) : undefined,
                passportNumber: item.citizen === types_1.Citizen.FOREIGN ? (0, validates_1.required)(item.passportNumber, translate) : undefined,
                firstname: (0, validates_1.required)(item.firstname, translate),
                lastname: (0, validates_1.required)(item.lastname, translate),
                phoneNumber: (0, validates_1.required)(item.phoneNumber, translate),
                email: !item.hasNoEmail ? (0, validates_1.required)(item.email, translate) : undefined,
                positionInfoErrors: item.positionInfos.map(positionInfo => {
                    const startDate = positionInfo.startDate && new Date((0, helper_1.transformParamsDate)(positionInfo.startDate));
                    const today = new Date();
                    return {
                        position: (0, validates_1.required)(positionInfo.position, translate),
                        workDirection: !positionInfo.workDirection && !positionInfo.selectWorkDirection ? 'სავალდებულო ველი' : undefined,
                        workPlaces: (0, validates_1.requiredNotEmptyArray)(positionInfo.workPlaces, translate),
                        startDate: !startDate
                            ? (0, validates_1.required)(positionInfo.startDate, translate)
                            : today > startDate
                                ? 'არჩეული თარიღი ნაკლები ან ტოლია დღევანდელ თარიღზე!'
                                : undefined,
                        basicFunctionDuties: (0, validates_1.required)(positionInfo.basicFunctionDuties, translate),
                        projectInfoErrors: positionInfo.projectInfos.map(projectInfo => {
                            return {
                                project: (0, validates_1.required)(projectInfo.project, translate),
                                workTypesInfoErrors: projectInfo.workInfos.map(workInfo => {
                                    return {
                                        workload: (0, validates_1.required)(workInfo.workload, translate),
                                        salaryType: (0, validates_1.required)(workInfo.salaryType, translate),
                                        salaryAmount: workInfo.salaryType && Number(workInfo.salaryType.id) === types_1.SalaryType.FIXED ? (0, validates_1.requiredPositiveNumber)(workInfo.salaryAmount, translate) : undefined,
                                        equivalentCurrency: workInfo.salaryType && Number(workInfo.salaryType.id) === types_1.SalaryType.FIXED ? (0, validates_1.required)(workInfo.equivalentCurrency, translate) : undefined,
                                        currency: workInfo.salaryType && Number(workInfo.salaryType.id) === types_1.SalaryType.FIXED ? (0, validates_1.required)(workInfo.currency, translate) : undefined,
                                        salaryGivenType: workInfo.salaryType && Number(workInfo.salaryType.id) === types_1.SalaryType.FIXED ? (0, validates_1.required)(workInfo.salaryGivenType, translate) : undefined,
                                        workType: workInfo.salaryType && Number(workInfo.salaryType.id) === types_1.SalaryType.BY_JOB ? (0, validates_1.required)(workInfo.workType, translate) : undefined,
                                        remunerationWork: workInfo.salaryType && Number(workInfo.salaryType.id) === types_1.SalaryType.BY_JOB ? (0, validates_1.required)(workInfo.remunerationWork, translate) : undefined,
                                        workTypeInfoItems: workInfo.salaryType && Number(workInfo.salaryType.id) !== types_1.SalaryType.FIXED
                                            ? Number(workInfo.salaryType.id) !== types_1.SalaryType.BY_JOB ? (0, exports.transformWorkInfoItemErrors)(workInfo, positionInfo.startDate, positionInfo.endDate, translate)
                                                : workInfo.remunerationWork ? (0, exports.transformWorkInfoItemErrors)(workInfo, positionInfo.startDate, positionInfo.endDate, translate) : []
                                            : []
                                    };
                                })
                            };
                        })
                    };
                })
            };
        })
    };
    const errors = {};
    Object.entries(formErrors).forEach(() => {
        formErrors.assignSubmissionItemsErrors = formErrors.assignSubmissionItemsErrors?.map(el => {
            if (el && (el.firstname
                || el.lastname
                || el.passportNumber
                || el.personalNumber
                || el.phoneNumber
                || el.email
                || (el?.positionInfoErrors).some(positionInfo => positionInfo.position
                    || positionInfo.startDate
                    || positionInfo.workPlaces
                    || positionInfo.basicFunctionDuties
                    || positionInfo.projectInfoErrors.some(projectInfo => projectInfo.project
                        || projectInfo.workTypesInfoErrors.some(workType => workType.workload
                            || workType.salaryType
                            || workType.salaryAmount
                            || workType.salaryGivenType
                            || workType.currency
                            || workType.equivalentCurrency
                            || workType.workType
                            || workType.remunerationWork
                            || workType.workTypeInfoItems.some(item => item.months
                                || item.dateRange
                                || item.salaryAmount
                                || item.salaryGivenType
                                || item.supplementAmount
                                || item.unitName
                                || item.amount
                                || item.administrativeOrdersArticle
                                || item.tariff
                                || item.volumeWorkPerformed
                                || item.year
                                || item.currency
                                || item.equivalentCurrency)))))) {
                return el;
            }
            return;
        });
        if (formErrors.assignSubmissionItemsErrors.some(err => err)) {
            errors.assignSubmissionItemsErrors = formErrors.assignSubmissionItemsErrors;
        }
    });
    return errors;
};
exports.assignSubmissionItemsFormValidation = assignSubmissionItemsFormValidation;
const transformWorkInfoItemErrors = (workInfo, contractStartDate, contractEndDate, translate) => {
    const type = Number(workInfo.salaryType.id);
    switch (type) {
        case types_1.SalaryType.FIXED_RESERVATION:
            return workInfo.fixedReservations.map(item => transformFixedReservationErrors(item, contractStartDate, contractEndDate, translate));
        case types_1.SalaryType.SUPPLEMENT:
            return workInfo.supplements.map(item => transformSupplementsErrors(item, contractStartDate, contractEndDate, translate));
        case types_1.SalaryType.BY_JOB:
            {
                if (workInfo.remunerationWork?.id !== String(types_2.RemunerationWorkPerformed.UNIT_FEE)) {
                    return workInfo.remunerationWorks.map(item => transformRemunerationWorkErrors(item, contractStartDate, contractEndDate, translate));
                }
                return workInfo.unitFees.map(item => transformWorkInfoItemUnitFee(item, translate));
            }
        case types_1.SalaryType.FIXED:
        case types_1.SalaryType.UNPAID:
            return [];
    }
};
exports.transformWorkInfoItemErrors = transformWorkInfoItemErrors;
const transformWorkInfoItemUnitFee = (item, translate) => {
    return {
        unitName: (0, validates_1.required)(item.unitName, translate),
        amount: item.unitName && Number(item.unitName.id) !== types_2.UnitNames.ORDER_OR_IA_PRICE_LIST ? item.unitName && !item.administrativeOrdersArticle || !item.tariff ? (0, validates_1.requiredPositiveNumber)(item.amount, translate) : undefined : undefined,
        tariff: !item.administrativeOrdersArticle ? (0, validates_1.requiredPositiveNumber)(item.tariff, translate) : undefined,
        administrativeOrdersArticle: !item.tariff ? (0, validates_1.required)(item.administrativeOrdersArticle, translate) : undefined,
        volumeWorkPerformed: item.amount ? (0, validates_1.requiredPositiveNumber)(item.volumeWorkPerformed, translate) : undefined,
        salaryGivenType: (0, validates_1.required)(item.salaryGivenType, translate),
        equivalentCurrency: (0, validates_1.required)(item.equivalentCurrency, translate),
        currency: (0, validates_1.required)(item.currency, translate)
    };
};
const transformFixedReservationErrors = (item, contractStartDate, contractEndDate, translate) => {
    const modifiedMonths = item.months && (0, exports.checkAssignMonthsRange)(item.months, contractStartDate, contractEndDate, item.isContractFullPeriod);
    return {
        year: item.isDate && item.year ? (0, exports.checkAssignYearRange)(item.isContractFullPeriod, translate, item.year, contractStartDate, contractEndDate) : undefined,
        months: item.isDate ? modifiedMonths?.some(month => month.alert) ? translate('selected_month_months_do_not_period', 'არჩეული თვე/თვეები არ შეესაბამება კონტრაქტის ვადას') : (0, validates_1.requiredNotEmptyArray)(item.months, translate) : undefined,
        dateRange: !item.isDate ? !item.dateRange ? translate('date_range_is_required', 'სავალდებულოა თარიღის დიაპაზონი') : undefined : undefined,
        salaryAmount: (0, validates_1.requiredPositiveNumber)(item.salaryAmount, translate),
        salaryGivenType: (0, validates_1.required)(item.salaryGivenType, translate),
        equivalentCurrency: (0, validates_1.required)(item.equivalentCurrency, translate),
        currency: (0, validates_1.required)(item.currency, translate)
    };
};
const transformSupplementsErrors = (item, contractStartDate, contractEndDate, translate) => {
    const modifiedMonths = item.months && (0, exports.checkAssignMonthsRange)(item.months, contractStartDate, contractEndDate, item.isContractFullPeriod);
    return {
        year: item.isDate && item.year ? (0, exports.checkAssignYearRange)(item.isContractFullPeriod, translate, item.year, contractStartDate, contractEndDate) : undefined,
        months: item.isDate ? modifiedMonths?.some(month => month.alert) ? translate('selected_month_months_do_not_period', 'არჩეული თვე/თვეები არ შეესაბამება კონტრაქტის ვადას') : (0, validates_1.requiredNotEmptyArray)(item.months, translate) : undefined,
        dateRange: !item.isDate ? !item.dateRange ? translate('date_range_is_required', 'სავალდებულოა თარიღის დიაპაზონი') : undefined : undefined,
        salaryGivenType: (0, validates_1.required)(item.salaryGivenType, translate),
        supplementAmount: (0, validates_1.requiredPositiveNumber)(item.supplementAmount, translate),
        equivalentCurrency: (0, validates_1.required)(item.equivalentCurrency, translate),
        currency: (0, validates_1.required)(item.currency, translate)
    };
};
const transformRemunerationWorkErrors = (item, contractStartDate, contractEndDate, translate) => {
    const modifiedMonths = item.months && (0, exports.checkAssignMonthsRange)(item.months, contractStartDate, contractEndDate, item.isContractFullPeriod);
    return {
        year: item.isDate && item.year ? (0, exports.checkAssignYearRange)(item.isContractFullPeriod, translate, item.year, contractStartDate, contractEndDate) : undefined,
        months: item.isDate ? modifiedMonths?.some(month => month.alert) ? translate('selected_month_months_do_not_period', 'არჩეული თვე/თვეები არ შეესაბამება კონტრაქტის ვადას') : (0, validates_1.requiredNotEmptyArray)(item.months, translate) : undefined,
        dateRange: !item.isDate ? !item.dateRange ? translate('date_range_is_required', 'სავალდებულოა თარიღის დიაპაზონი') : undefined : undefined,
        salaryGivenType: (0, validates_1.required)(item.salaryGivenType, translate),
        salaryAmount: (0, validates_1.requiredPositiveNumber)(item.salaryAmount, translate),
        equivalentCurrency: (0, validates_1.required)(item.equivalentCurrency, translate),
        currency: (0, validates_1.required)(item.currency, translate)
    };
};
const checkYearValidityForAssignSubmission = (positionInfo, startDate, isChangedDate, endDate) => {
    const startDateYear = Number(startDate?.split('-')[2]);
    const endDateYear = Number(endDate?.split('-')[2]);
    return positionInfo.projectInfos.map(projectInfo => {
        return {
            ...projectInfo,
            workInfos: (0, exports.transformWorkInfoYearValidity)(projectInfo.workInfos, startDateYear, endDateYear, endDate, isChangedDate)
        };
    });
};
exports.checkYearValidityForAssignSubmission = checkYearValidityForAssignSubmission;
const checkYearValidityForAssignSubmissionVerify = (positionInfo, startDate, isChangedDate, endDate) => {
    const startDateYear = Number(startDate?.split('-')[2]);
    const endDateYear = Number(endDate?.split('-')[2]);
    return positionInfo.projectInfos.map(projectInfo => {
        return {
            ...projectInfo,
            workInfos: (0, exports.transformWorkInfoYearValidity)(projectInfo.workInfos, startDateYear, endDateYear, endDate, isChangedDate)
        };
    });
};
exports.checkYearValidityForAssignSubmissionVerify = checkYearValidityForAssignSubmissionVerify;
const transformWorkInfoYearValidity = (workInfos, startDateYear, endDateYear, endDate, isChangedDate) => {
    return workInfos.map(workInfo => {
        return {
            ...workInfo,
            fixedReservations: Number(workInfo.salaryType?.id) === types_1.SalaryType.FIXED_RESERVATION
                ? workInfo.fixedReservations.map(fixedReservation => {
                    return {
                        ...fixedReservation,
                        dateRange: !fixedReservation.isDate && isChangedDate ? null : fixedReservation.dateRange,
                        isValidYear: !fixedReservation.isContractFullPeriod
                            ? fixedReservation.year
                                ? endDate === null
                                    ? startDateYear <= fixedReservation.year //when we do not have end date
                                    : startDateYear <= fixedReservation.year && endDateYear >= fixedReservation.year //when we have end date
                                : false
                            : true
                    };
                })
                : [],
            supplements: Number(workInfo.salaryType?.id) === types_1.SalaryType.SUPPLEMENT
                ? workInfo.supplements.map(supplement => {
                    return {
                        ...supplement,
                        dateRange: !supplement.isDate && isChangedDate ? null : supplement.dateRange,
                        isValidYear: !supplement.isContractFullPeriod
                            ? supplement.year
                                ? endDate === null
                                    ? startDateYear <= supplement.year //when we do not have end date
                                    : startDateYear <= supplement.year && endDateYear >= supplement.year //when we have end date
                                : false
                            : true
                    };
                })
                : [],
            remunerationWorks: Number(workInfo.salaryType?.id) === types_1.SalaryType.BY_JOB && Number(workInfo.remunerationWork?.id) !== types_2.RemunerationWorkPerformed.UNIT_FEE
                ? workInfo.remunerationWorks.map(remunerationWork => {
                    return {
                        ...remunerationWork,
                        dateRange: !remunerationWork.isDate && isChangedDate ? null : remunerationWork.dateRange,
                        isValidYear: !remunerationWork.isContractFullPeriod
                            ? remunerationWork.year
                                ? endDate === null
                                    ? startDateYear <= remunerationWork.year //when we do not have end date
                                    : startDateYear <= remunerationWork.year && endDateYear >= remunerationWork.year //when we have end date
                                : false
                            : true
                    };
                })
                : []
        };
    });
};
exports.transformWorkInfoYearValidity = transformWorkInfoYearValidity;
const checkAssignYearRange = (isContractFullPeriod, translate, year, contractStartDate, contractEndDate) => {
    if (isContractFullPeriod)
        return undefined;
    return !contractEndDate
        ? Number(contractStartDate?.split('-')[2]) <= year ? undefined : translate('required_positive_number', 'სავალდებულოა დადებითი რიცხვი')
        : (Number(contractStartDate?.split('-')[2]) <= year && Number(contractEndDate?.split('-')[2]) >= year) ? undefined : translate('required_positive_number', 'სავალდებულოა დადებითი რიცხვი');
};
exports.checkAssignYearRange = checkAssignYearRange;
const checkAssignMonthsRange = (months, contractStartDate, contractEndDate, isContractFullPeriod) => {
    const contractStartDateYear = Number(contractStartDate?.split('-')[2]);
    const contractEndDateYear = Number(contractEndDate?.split('-')[2]);
    const contractStartDateMonth = Number(contractStartDate?.split('-')[1]);
    const contractEndDateMonth = Number(contractEndDate?.split('-')[1]);
    const contractStartDateDay = Number(contractStartDate?.split('-')[0]);
    const contractEndDateDay = Number(contractEndDate?.split('-')[0]);
    if (isContractFullPeriod) {
        if (contractEndDate) {
            if (contractEndDateYear === contractStartDateYear) {
                if (months.every(el => Number(el.id) === contractStartDateMonth && Number(el.id) === contractEndDateMonth)) {
                    return months.map(month => {
                        if (!(1 >= contractStartDateDay && 30 <= contractEndDateDay))
                            return { ...month, alert: 'danger' };
                        return month;
                    });
                }
                return months.map(month => {
                    if (!(Number(month.id) >= contractStartDateMonth && Number(month.id) <= contractEndDateMonth))
                        return { ...month, alert: 'danger' };
                    return month;
                });
            }
            return months.map(month => {
                if ((Number(month.id) < contractStartDateMonth && Number(month.id) > contractEndDateMonth))
                    return { ...month, alert: 'danger' };
                return month;
            });
        }
        else
            return months;
    }
    return months.map(month => {
        return !contractEndDate
            ? Number(month.id) < contractStartDateMonth
                ? { ...month, alert: 'danger' }
                : month
            : Number(month.id) === contractStartDateMonth || Number(month.id) === contractEndDateMonth || (Number(month.id) > contractStartDateMonth && Number(month.id) < contractEndDateMonth)
                ? month
                : { ...month, alert: 'danger' };
    });
};
exports.checkAssignMonthsRange = checkAssignMonthsRange;
const transformYear = (contractStartDate) => Number(contractStartDate.split('-')[2]);
exports.transformYear = transformYear;
