"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const SelectListType = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    const ref = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        const outsideClick = (e) => {
            if (!e.target.closest('#work-type-listing'))
                props.onHide();
        };
        document.body.addEventListener('click', outsideClick);
        return () => {
            document.body.removeEventListener('click', outsideClick);
        };
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", { className: 'name-For-position overflow-auto', id: 'work-type-listing', ref: ref, children: (0, jsx_runtime_1.jsx)("div", { className: 'p-3 mt-2 card-scroll list', children: (0, jsx_runtime_1.jsxs)("ul", { className: 'list-hover pointer', children: [props.data.map((el, index) => {
                        return ((0, jsx_runtime_1.jsxs)("li", { className: 'p-1 item-hover ', onClick: () => {
                                props.setValue(el);
                                props.onHide();
                            }, children: [" ", el.label] }, index));
                    }), props.creatable && props.value
                        ? (0, jsx_runtime_1.jsxs)("li", { className: 'p-1 item-hover', onClick: () => { props.onCreateOption && props.onCreateOption(props.value || ''); }, children: [translate('button_create', 'შექმნა'), ": ", (0, jsx_runtime_1.jsx)("b", { children: props.value })] })
                        : null] }) }) }));
};
exports.default = SelectListType;
