"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hrEmployeeAndStructuralUnitReducer = exports.useHrEmployeeAndStructuralUnitReducer = void 0;
const react_1 = require("react");
const initialHrEmployeeAndStructuralUnitFormValues = {
    hrEmployeeList: []
};
const initialHrEmployeeAndStructuralUnitReducer = {
    data: initialHrEmployeeAndStructuralUnitFormValues,
    loadingCheckbox: [],
    errors: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false
};
const useHrEmployeeAndStructuralUnitReducer = () => {
    return (0, react_1.useReducer)(exports.hrEmployeeAndStructuralUnitReducer, initialHrEmployeeAndStructuralUnitReducer);
};
exports.useHrEmployeeAndStructuralUnitReducer = useHrEmployeeAndStructuralUnitReducer;
const hrEmployeeAndStructuralUnitReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_GET_HR_EMPLOYEE_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_GET_HR_EMPLOYEE_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    hrEmployeeList: action.payload.hrEmployees.data?.map(el => {
                        const selectedAccount = action.payload.attachedAccounts.data?.find(element => el.accountId === element.accountId);
                        return {
                            accountId: el.accountId,
                            name: el.name,
                            position: el.position,
                            createdAt: selectedAccount ? selectedAccount.createdAt : null,
                            checked: !!selectedAccount,
                            attachedBy: selectedAccount ? selectedAccount.attachedBy : null,
                            authorPosition: selectedAccount ? selectedAccount.authorPosition : null
                        };
                    }) || state.data.hrEmployeeList
                },
                errors: action.payload.hrEmployees.errors,
                isFetching: false,
                isLoading: state.isUpdating || state.isCreating
            };
        case 'SEND_GET_CHECKED_HR_EMPLOYEE_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_GET_CHECKED_HR_EMPLOYEE_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    hrEmployeeList: action.payload.attachedAccounts.data
                        ? state.data.hrEmployeeList.map(el => {
                            const selectedAccount = action.payload.attachedAccounts.data.find(element => el.accountId === element.accountId);
                            return {
                                accountId: el.accountId,
                                name: el.name,
                                position: el.position,
                                createdAt: selectedAccount ? selectedAccount.createdAt : null,
                                checked: !!selectedAccount,
                                attachedBy: selectedAccount ? selectedAccount.attachedBy : null,
                                authorPosition: selectedAccount ? selectedAccount.authorPosition : null
                            };
                        })
                        : state.data.hrEmployeeList
                },
                isFetching: false,
                isLoading: state.isUpdating || state.isCreating
            };
        case 'SEND_EMPLOYEE_ATTACH_REQUEST':
            return {
                ...state,
                loadingCheckbox: [...state.loadingCheckbox, action.payload],
                isLoading: true
            };
        case 'DONE_EMPLOYEE_ATTACH_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    hrEmployeeList: state.data.hrEmployeeList.map(el => {
                        const selectedAccount = action.payload.attachedAccounts.data?.find(element => el.accountId === element.accountId);
                        return {
                            accountId: el.accountId,
                            name: el.name,
                            position: el.position,
                            createdAt: selectedAccount ? selectedAccount.createdAt : null,
                            checked: !!selectedAccount,
                            attachedBy: selectedAccount ? selectedAccount.attachedBy : null,
                            authorPosition: selectedAccount ? selectedAccount.authorPosition : null
                        };
                    }) || state.data.hrEmployeeList
                },
                loadingCheckbox: state.loadingCheckbox.filter(el => el !== action.payload.accountId),
                isLoading: false
            };
        case 'SEND_EMPLOYEE_DETACH_REQUEST':
            return {
                ...state,
                loadingCheckbox: [...state.loadingCheckbox, action.payload],
                isLoading: true
            };
        case 'DONE_EMPLOYEE_DETACH_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    hrEmployeeList: state.data.hrEmployeeList.map(el => {
                        return {
                            accountId: el.accountId,
                            name: el.name,
                            position: el.position,
                            createdAt: el.accountId === action.payload ? null : el.createdAt,
                            checked: el.accountId === action.payload ? false : el.checked,
                            attachedBy: el.accountId === action.payload ? null : el.attachedBy,
                            authorPosition: el.accountId === action.payload ? null : el.authorPosition
                        };
                    })
                },
                loadingCheckbox: state.loadingCheckbox.filter(el => el !== action.payload),
                isLoading: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default: {
            return state;
        }
    }
};
exports.hrEmployeeAndStructuralUnitReducer = hrEmployeeAndStructuralUnitReducer;
