"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdministrativeBuildingFormValidation = void 0;
const validates_1 = require("core/helpers/validates");
const AdministrativeBuildingFormValidation = (values, translate) => {
    const formErrors = {
        address: (0, validates_1.required)(values.address, translate),
        name: (0, validates_1.required)(values.name, translate),
        district: (0, validates_1.required)(values.district, translate)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.AdministrativeBuildingFormValidation = AdministrativeBuildingFormValidation;
