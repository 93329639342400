"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractType = exports.ProcurementSubStatuses = exports.ProcurementStatuses = exports.ProcurementItemType = exports.ApproverStatus = exports.TemplateTypes = void 0;
// export type TemplateType = TemplateTypes
var TemplateTypes;
(function (TemplateTypes) {
    TemplateTypes[TemplateTypes["ELECTRONIC"] = 1] = "ELECTRONIC";
    TemplateTypes[TemplateTypes["SIMPLIFIED"] = 2] = "SIMPLIFIED";
    TemplateTypes[TemplateTypes["CONSOLIDATED"] = 3] = "CONSOLIDATED";
    TemplateTypes[TemplateTypes["SIGNED"] = 4] = "SIGNED";
    TemplateTypes[TemplateTypes["SIGNATURE_DOCUMENT"] = 5] = "SIGNATURE_DOCUMENT";
    TemplateTypes[TemplateTypes["ADDITIONAL_FILES"] = 6] = "ADDITIONAL_FILES";
    TemplateTypes[TemplateTypes["NON_STATE_PROCUREMENT"] = 7] = "NON_STATE_PROCUREMENT";
})(TemplateTypes || (exports.TemplateTypes = TemplateTypes = {}));
var ApproverStatus;
(function (ApproverStatus) {
    ApproverStatus[ApproverStatus["PENDING"] = 0] = "PENDING";
    ApproverStatus[ApproverStatus["APPROVED"] = 1] = "APPROVED";
    ApproverStatus[ApproverStatus["DECLINED"] = 2] = "DECLINED";
    ApproverStatus[ApproverStatus["SUSPENDED"] = 3] = "SUSPENDED";
})(ApproverStatus || (exports.ApproverStatus = ApproverStatus = {}));
var ProcurementItemType;
(function (ProcurementItemType) {
    ProcurementItemType[ProcurementItemType["VERIFY_ITEM"] = 1] = "VERIFY_ITEM";
    ProcurementItemType[ProcurementItemType["SUBMISSION_ITEM"] = 2] = "SUBMISSION_ITEM";
})(ProcurementItemType || (exports.ProcurementItemType = ProcurementItemType = {}));
var ProcurementStatuses;
(function (ProcurementStatuses) {
    ProcurementStatuses[ProcurementStatuses["DRAFT"] = 1] = "DRAFT";
    ProcurementStatuses[ProcurementStatuses["APPROVAL_PROGRESS"] = 2] = "APPROVAL_PROGRESS";
    ProcurementStatuses[ProcurementStatuses["SIGN_PROGRESS"] = 3] = "SIGN_PROGRESS";
    ProcurementStatuses[ProcurementStatuses["DECLINED"] = 4] = "DECLINED";
    ProcurementStatuses[ProcurementStatuses["SIGNED"] = 5] = "SIGNED";
    ProcurementStatuses[ProcurementStatuses["CORRECTED"] = 6] = "CORRECTED";
    ProcurementStatuses[ProcurementStatuses["READY_FOR_CORRECTION"] = 7] = "READY_FOR_CORRECTION";
    ProcurementStatuses[ProcurementStatuses["CORRECTION_PROCESS"] = 8] = "CORRECTION_PROCESS";
    ProcurementStatuses[ProcurementStatuses["REVERTED"] = 9] = "REVERTED";
    ProcurementStatuses[ProcurementStatuses["SUSPENDED"] = 10] = "SUSPENDED";
    ProcurementStatuses[ProcurementStatuses["SENT_FOR_SIGNATURE"] = 11] = "SENT_FOR_SIGNATURE";
})(ProcurementStatuses || (exports.ProcurementStatuses = ProcurementStatuses = {}));
var ProcurementSubStatuses;
(function (ProcurementSubStatuses) {
    ProcurementSubStatuses[ProcurementSubStatuses["NOT_EXECUTED"] = 1] = "NOT_EXECUTED";
    ProcurementSubStatuses[ProcurementSubStatuses["PARTIALLY_EXECUTED"] = 2] = "PARTIALLY_EXECUTED";
    ProcurementSubStatuses[ProcurementSubStatuses["EXECUTED"] = 3] = "EXECUTED";
    ProcurementSubStatuses[ProcurementSubStatuses["ACTUALLY_DONE"] = 4] = "ACTUALLY_DONE"; // SUB_STATUS_ACTUALLY_DONE
})(ProcurementSubStatuses || (exports.ProcurementSubStatuses = ProcurementSubStatuses = {}));
var ContractType;
(function (ContractType) {
    ContractType[ContractType["GOODS"] = 1] = "GOODS";
    ContractType[ContractType["SERVICE"] = 2] = "SERVICE";
    ContractType[ContractType["JOB"] = 3] = "JOB";
    ContractType[ContractType["GOODS_WITH_SERVICES"] = 4] = "GOODS_WITH_SERVICES";
    ContractType[ContractType["CORRECTION"] = 5] = "CORRECTION";
    ContractType[ContractType["CONTRACT_TYPE_GOODS_WITH_GUARANTEE"] = 6] = "CONTRACT_TYPE_GOODS_WITH_GUARANTEE";
})(ContractType || (exports.ContractType = ContractType = {}));
