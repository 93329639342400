"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFakeProcurement = void 0;
const index_1 = require("api/index");
const types_1 = require("api/documents/purchases/procurements/types");
const types_2 = require("api/documents/purchases/procurementCorrections/types");
const helper_1 = require("../../../common/helper");
const companies_1 = require("api/references/companies");
const employees_1 = require("api/references/employees");
const helpers_1 = require("core/helpers");
const useAccountChange_1 = require("src/modules/debuging/hooks/useAccountChange");
const useFakeSubmissionVerify_1 = require("./useFakeSubmissionVerify");
const react_1 = require("react");
const context_1 = require("src/context/context");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const data = {
    deliveryDate: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
    contractValidityDate: (0, helper_1.transformParamsDate)((0, helper_1.getTomorrow)()),
    advanceGuaranteeValidityPeriod: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
    contractGuaranteeValidityPeriod: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
    goodsGuaranteeValidityPeriod: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
    typeId: '1',
    legalBasisId: '1',
    guaranteeArticleIds: ['1'],
    templateType: (0, helpers_1.getRandomIntBetween)(5, 1),
    template: (0, helpers_1.getHash)(),
    fileUids: []
};
const signatureData = {
    items: [
        {
            firstname: 'name',
            lastname: 'lastname',
            position: (0, helpers_1.getHash)(),
            phones: [
                {
                    phone: '5' + Math.random().toString().slice(2, 10)
                }
            ]
        }
    ]
};
const bankData = {
    items: [
        {
            name: 'test name',
            code: 'test code',
            accountNumber: 'test number',
            status: true
        }
    ]
};
const addressData = {
    items: [
        {
            address: 'test address',
            type: 1
        }
    ]
};
const useFakeProcurement = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    const [isCreating, setIsCreating] = (0, react_1.useState)(false);
    const [isAddingAndRemovingApprover, setIsAddingAndRemovingApprover] = (0, react_1.useState)(false);
    const [isSendingDocument, setIsSendingDocument] = (0, react_1.useState)(false);
    const [isCorrecting, setIsCorrecting] = (0, react_1.useState)(false);
    const [isCreatingCorrection, setIsCreatingCorrection] = (0, react_1.useState)(false);
    const [isUpdatingCorrection, setIsUpdatingCorrection] = (0, react_1.useState)(false);
    const [isAddingAndRemovingCorrectionApprover, setIsAddingAndRemovingCorrectionApprover] = (0, react_1.useState)(false);
    const [isSendingCorrectionDocument, setIsSendingCorrectionDocument] = (0, react_1.useState)(false);
    const hook = (0, useFakeSubmissionVerify_1.useFakeSubmissionVerify)({ auth: props.auth, uid: props.uid });
    const purchasesDepartmentAccount = props.auth.state.data.user?.accounts.find(el => el.name === 'შესყიდვების დეპარტამენტის უფროსი' || el.name === 'შესყიდვების სამსახურის უფროსი');
    const budgetAccount = props.auth.state.data.user?.accounts.find(el => el.name === 'ბიუჯეტირების დეპარტამენტის უფროსი' || el.name === 'ბიუჯეტირებისა და ფინანსური ანალიზის სამსახურის უფროსი');
    const headOfPurchasesStructuralUnitDev = 'შესყიდვების დეპარტამენტის უფროსი';
    const headOfPurchasesStructuralUnitStaging = 'შესყიდვების სამსახურის უფროსი';
    const headOfBudgetingStructuralUnitDev = 'ბიუჯეტირების დეპარტამენტის უფროსი';
    const headOfBudgetingStructuralUnitStaging = 'ბიუჯეტირებისა და ფინანსური ანალიზის სამსახურის უფროსი';
    const useAccountChangeHook = (0, useAccountChange_1.useAccountChange)({ authUser: props.auth.state.data.user });
    const { toast } = (0, context_1.useToastContext)();
    const createProcurement = async (loading = true) => {
        if (loading)
            setIsCreating(true);
        const company = await createCompany();
        if (company?.data) {
            const companyAddressId = company.data.addresses.map(el => el.id);
            const companyBankAccountId = company.data.bankAccounts.map(el => el.id);
            const companySignatoryId = company.data.signatories.map(el => el.id);
            const companyData = {
                companyId: company.data.id,
                companyAddressId: companyAddressId[0],
                companyBankAccountId: companyBankAccountId[0],
                companySignatoryId: companySignatoryId[0]
            };
            const approvedItems = await hook.approveItems(false);
            const emulatedUser = await useAccountChangeHook.changeUserOrAccount(headOfPurchasesStructuralUnitDev, headOfPurchasesStructuralUnitStaging, purchasesDepartmentAccount);
            if (approvedItems) {
                const items = approvedItems.verifySubmissionItems.map(el => {
                    return {
                        id: null,
                        verifyItemId: el.id,
                        budgetArticleId: String((0, helpers_1.getRandomIntBetween)(20, 10)),
                        price: el.price
                    };
                });
                const fullData = { ...data, ...companyData };
                const procurement = await index_1.ProcurementsApi.createProcurement(fullData, true);
                if (loading)
                    setIsCreating(false);
                if (procurement.data !== null) {
                    const procurementItems = await index_1.ProcurementsApi.updateProcurementItem(procurement.data.id, { items });
                    if (emulatedUser?.id)
                        await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                    if (procurementItems.data !== null) {
                        toast.success(translate('procurement_successfully_created', 'შესყიდვა/ხელშეკრულების დოკუმენტი შეიქმნა წარმატებით'));
                    }
                    else {
                        toast.danger(translate('procurement_could_not_be_created', 'შესყიდვა/ხელშეკრულების დოკუმენტი ვერ შეიქმნა'));
                    }
                    return procurement.data;
                }
                else {
                    if (emulatedUser?.id) {
                        await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                    }
                    if (loading)
                        setIsCreating(false);
                    toast.danger(translate('procurement_could_not_be_created', 'შესყიდვა/ხელშეკრულების დოკუმენტი ვერ შეიქმნა'));
                    return null;
                }
            }
            else {
                if (emulatedUser?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                return null;
            }
        }
        return null;
    };
    const addAndRemoveFakeApprover = async (loading = true) => {
        if (loading)
            setIsAddingAndRemovingApprover(true);
        const procurement = await createProcurement(false);
        if (procurement === null) {
            if (loading)
                setIsAddingAndRemovingApprover(false);
            return null;
        }
        const emulatedByPurchases = await useAccountChangeHook.changeUserOrAccount(headOfPurchasesStructuralUnitDev, headOfPurchasesStructuralUnitStaging, purchasesDepartmentAccount);
        const devAccounts = await (0, employees_1.getEmployeesForSelectStructuralUnits)({ filters: { keyword: 'Alda Reynolds' } });
        const devAccount = devAccounts[0]?.structuralUnits.find(el => el.label === 'ბიუჯეტირების დეპარტამენტის უფროსი');
        const stagingAccounts = await (0, employees_1.getEmployeesForSelectStructuralUnits)({ filters: { keyword: 'ეკატერინე გაფრინდაშვილი' } });
        const stagingAccount = stagingAccounts[0]?.structuralUnits.find(el => el.label === 'ბიუჯეტირებისა და ფინანსური ანალიზის სამსახურის უფროსი');
        if (!devAccount && !stagingAccount) {
            if (emulatedByPurchases?.id)
                await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
            if (loading)
                setIsAddingAndRemovingApprover(false);
            return;
        }
        const procurementId = procurement?.id;
        if (procurementId) {
            const procurementApprover = await index_1.ProcurementsApi.addApprover(procurementId, { accountId: devAccount ? devAccount.accountId : stagingAccount.accountId });
            if (!procurementApprover) {
                if (emulatedByPurchases?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                if (loading)
                    setIsAddingAndRemovingApprover(false);
                return;
            }
            else {
                const response = await index_1.ProcurementsApi.sendForVerify(procurementId);
                if (emulatedByPurchases?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                if (!response)
                    toast.danger(translate('procurement_could_not_sent_for_confirm', 'შესყიდვა/ხელშეკრულება ვერ გაიგზავნა დასადასტურებლად'));
                const emulatedByBudgeting = await useAccountChangeHook.changeUserOrAccount(headOfBudgetingStructuralUnitDev, headOfBudgetingStructuralUnitStaging, budgetAccount);
                const procurement = await index_1.ProcurementsApi.sendApproval(procurementId, true);
                if (emulatedByBudgeting?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                if (procurement.data) {
                    toast.success(translate('procurement_signature_successfully', 'შესყიდვა/ხელშეკრულება გადავიდა ხელმოწერის პროცესში წარმატებით'));
                    if (loading)
                        setIsAddingAndRemovingApprover(false);
                    return procurement.data;
                }
                else {
                    toast.danger(translate('procurement_signature_failed', 'შესყიდვა/ხელშეკრულება ვერ გადავიდა ხელმოწერის პროცესში წარმატებით'));
                    if (loading)
                        setIsAddingAndRemovingApprover(false);
                    return null;
                }
            }
        }
        return null;
    };
    const sendFakeDocument = async (loading = true) => {
        if (loading)
            setIsSendingDocument(true);
        const procurement = await addAndRemoveFakeApprover(false);
        if (procurement === null) {
            if (loading)
                setIsSendingDocument(false);
            return null;
        }
        const emulatedByPurchases = await useAccountChangeHook.changeUserOrAccount(headOfPurchasesStructuralUnitDev, headOfPurchasesStructuralUnitStaging, purchasesDepartmentAccount);
        const procurementId = procurement?.id;
        const documentData = {
            signedDocumentNumber: (0, helpers_1.getHash)(),
            dateOfConclusion: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
            fileUid: props.uid,
            fileType: 5
        };
        const signedData = {
            fileUid: props.uid,
            fileType: 4
        };
        if (procurementId) {
            const attachSignature = await index_1.ProcurementsApi.attachSignature(procurementId, documentData, true);
            if (!attachSignature.data) {
                if (loading)
                    setIsSendingDocument(false);
                if (emulatedByPurchases?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                return null;
            }
            const sendSignatureDocument = await index_1.ProcurementsApi.sendSignatureDocument(procurementId, true);
            if (!sendSignatureDocument.data) {
                if (emulatedByPurchases?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                if (loading)
                    setIsSendingDocument(false);
                return null;
            }
            const response = await index_1.ProcurementsApi.attachSignedDocument(procurementId, signedData, true);
            if (emulatedByPurchases?.id)
                await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
            if (loading)
                setIsSendingDocument(false);
            if (response.data !== null) {
                toast.success(translate('procurement_sent_successfully', 'შესყიდვა/ხელშეკრულების დოკუმენტი გაიგზავნა წარმატებით'));
                return procurement;
            }
            else {
                toast.danger(translate('procurement_could_not_sent', 'შესყიდვა/ხელშეკრულების დოკუმენტი ვერ გაიგზავნა წარმატებით'));
                return null;
            }
        }
        return null;
    };
    const createFakeProcurementCorrection = async (loading = true) => {
        if (loading)
            setIsCreatingCorrection(true);
        const procurement = await sendFakeDocument(false);
        if (procurement === null) {
            if (loading)
                setIsCreatingCorrection(false);
            return;
        }
        const emulatedByPurchases = await useAccountChangeHook.changeUserOrAccount(headOfPurchasesStructuralUnitDev, headOfPurchasesStructuralUnitStaging, purchasesDepartmentAccount);
        const procurementId = procurement?.id;
        if (props.uid) {
            const data = {
                type: types_2.ProcurementCorrectionType.LETTER,
                fileUids: [props.uid],
                reasons: [1],
                letterNumber: (0, helpers_1.getHash)(),
                letterDate: (0, helper_1.transformParamsDate)((0, helper_1.getToday)())
            };
            if (procurementId) {
                const procurementCorrectionData = await index_1.ProcurementCorrectionsApi.creteProcurementCorrection(procurementId, data);
                if (emulatedByPurchases?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                if (loading)
                    setIsCreatingCorrection(false);
                if (procurementCorrectionData.data !== null) {
                    toast.success(translate('correction_created_successfully', 'შესყიდვა/ხელშეკრულების კორექცია შეიქმნა წარმატებით'));
                    return {
                        procurementCorrectionData,
                        procurementId
                    };
                }
                else {
                    toast.danger(translate('correction_could_not_created', 'შესყიდვა/ხელშეკრულების კორექცია ვერ შეიქმნა წარმატებით'));
                    return null;
                }
            }
        }
        return null;
    };
    const updateFakeProcurementCorrection = async (loading = true) => {
        if (loading)
            setIsUpdatingCorrection(true);
        const procurementCorrection = await createFakeProcurementCorrection(false);
        if (procurementCorrection === null) {
            if (loading)
                setIsUpdatingCorrection(false);
            return;
        }
        const emulatedByPurchases = await useAccountChangeHook.changeUserOrAccount(headOfPurchasesStructuralUnitDev, headOfPurchasesStructuralUnitStaging, purchasesDepartmentAccount);
        if (props.uid) {
            const updateData = {
                templateType: types_1.TemplateTypes.SIMPLIFIED,
                typeId: '1',
                legalBasisId: '1',
                guaranteeArticleIds: ['1'],
                goodsGuaranteeValidityPeriod: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
                fileUids: [{ uid: props.uid, type: 2, is_other: true }],
                advanceGuaranteeValidityPeriod: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
                companyAddressId: procurementCorrection?.procurementCorrectionData?.data?.companyAddress?.id,
                companyBankAccountId: procurementCorrection?.procurementCorrectionData?.data?.companyBankAccount?.id,
                companySignatoryId: procurementCorrection?.procurementCorrectionData?.data?.companySignatory?.id,
                companyId: procurementCorrection?.procurementCorrectionData?.data?.company.id,
                template: '',
                contractGuaranteeValidityPeriod: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
                contractValidityDate: (0, helper_1.transformParamsDate)((0, helper_1.getTomorrow)()),
                deliveryDate: (0, helper_1.transformParamsDate)((0, helper_1.dateToAdd)(null, null, 10))
            };
            const response = await index_1.ProcurementsApi.updateProcurement(procurementCorrection?.procurementCorrectionData?.data?.id, updateData, false);
            const sendForVerify = await index_1.ProcurementsApi.sendForVerify(procurementCorrection?.procurementCorrectionData?.data?.id);
            if (emulatedByPurchases?.id)
                await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
            if (!sendForVerify) {
                if (loading)
                    setIsUpdatingCorrection(false);
                return;
            }
            const procurementCorrectionId = procurementCorrection?.procurementCorrectionData?.data?.id;
            if (loading)
                setIsUpdatingCorrection(false);
            if (response.data !== null) {
                toast.success(translate('correction_updated_successfully', 'შესყიდვა/ხელშეკრულების კორექცია განახლდა წარმატებით'));
                return {
                    procurementCorrectionId,
                    procurementId: procurementCorrection?.procurementId
                };
            }
            else {
                toast.danger(translate('correction_could_not_update', 'შესყიდვა/ხელშეკრულების კორექცია ვერ განახლდა წარმატებით'));
                return null;
            }
        }
        return null;
    };
    const addAndRemoveFakeCorrectionApprover = async (loading = true) => {
        if (loading)
            setIsAddingAndRemovingCorrectionApprover(true);
        const procurement = await updateFakeProcurementCorrection(false);
        const emulatedByBudgeting = await useAccountChangeHook.changeUserOrAccount(headOfBudgetingStructuralUnitDev, headOfBudgetingStructuralUnitStaging, budgetAccount);
        if (procurement === null) {
            if (loading)
                setIsAddingAndRemovingCorrectionApprover(false);
            return;
        }
        const sendApproval = await index_1.ProcurementsApi.sendApproval(procurement?.procurementCorrectionId, true);
        if (emulatedByBudgeting?.id)
            await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
        if (loading)
            setIsAddingAndRemovingCorrectionApprover(false);
        if (sendApproval) {
            toast.success(translate('procurement_correction_signature_successfully', 'შესყიდვა/ხელშეკრულების კორექცია გადავიდა ხელმოწერის პროცესში წარმატებით'));
            return procurement;
        }
        else {
            toast.danger(translate('procurement_correction_signature_failed', 'შესყიდვა/ხელშეკრულების კორექცია ვერ გადავიდა ხელმოწერის პროცესში წარმატებით'));
            return null;
        }
    };
    const sendFakeCorrectionDocument = async (loading = true) => {
        if (loading)
            setIsSendingCorrectionDocument(true);
        const procurement = await addAndRemoveFakeCorrectionApprover(false);
        if (procurement === null) {
            if (loading)
                setIsSendingCorrectionDocument(false);
            return;
        }
        const emulatedByPurchases = await useAccountChangeHook.changeUserOrAccount(headOfPurchasesStructuralUnitDev, headOfPurchasesStructuralUnitStaging, purchasesDepartmentAccount);
        if (props.uid) {
            const documentData = {
                signedDocumentNumber: (0, helpers_1.getHash)(),
                dateOfConclusion: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
                fileUid: props.uid,
                fileType: 5
            };
            const signedData = {
                fileUid: props.uid,
                fileType: 4
            };
            const attachSignature = await index_1.ProcurementsApi.attachSignature(procurement?.procurementCorrectionId, documentData, false);
            if (!attachSignature.data) {
                if (emulatedByPurchases?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                if (loading)
                    setIsSendingCorrectionDocument(false);
                return null;
            }
            const sendSignatureDocument = await index_1.ProcurementsApi.sendSignatureDocument(procurement?.procurementCorrectionId, false);
            if (!sendSignatureDocument.data) {
                if (emulatedByPurchases?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                if (loading)
                    setIsSendingCorrectionDocument(false);
                return null;
            }
            const response = await index_1.ProcurementsApi.attachSignedDocument(procurement?.procurementCorrectionId, signedData, false);
            if (emulatedByPurchases?.id)
                await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
            if (loading)
                setIsSendingCorrectionDocument(false);
            if (response.data !== null) {
                toast.success(translate('procurement_successfully_adjusted', 'შესყიდვა/ხელშეკრულება წარმატებით დაკორექტირდა'));
                return procurement;
            }
            else {
                toast.danger(translate('procurement_could_not_adjusted', 'შესყიდვა/ხელშეკრულების წარმატებით ვერ დაკორექტირდა'));
                return null;
            }
        }
        return null;
    };
    const fakeCorrections = async (loading = true) => {
        if (loading)
            setIsCorrecting(true);
        const procurement = await sendFakeCorrectionDocument(false);
        if (procurement === null) {
            if (loading)
                setIsCorrecting(false);
            return;
        }
        const emulatedByPurchase = await useAccountChangeHook.changeUserOrAccount(headOfPurchasesStructuralUnitDev, headOfPurchasesStructuralUnitStaging, purchasesDepartmentAccount);
        if (props.uid) {
            const data = {
                type: types_2.ProcurementCorrectionType.LETTER,
                fileUids: [props.uid],
                reasons: [1],
                letterNumber: (0, helpers_1.getHash)(),
                letterDate: (0, helper_1.transformParamsDate)((0, helper_1.getToday)())
            };
            const procurementCorrection = await index_1.ProcurementCorrectionsApi.creteProcurementCorrection(procurement?.procurementId, data);
            if (!procurementCorrection.data) {
                if (loading)
                    setIsCorrecting(false);
                return;
            }
            const procurementCorrectionId = procurementCorrection.data?.id;
            const updateData = {
                templateType: types_1.TemplateTypes.SIMPLIFIED,
                typeId: '1',
                legalBasisId: '1',
                guaranteeArticleIds: ['1'],
                goodsGuaranteeValidityPeriod: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
                fileUids: [{ uid: props.uid, type: 2, is_other: true }],
                advanceGuaranteeValidityPeriod: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
                companyAddressId: procurementCorrection?.data?.companyAddress?.id,
                companyBankAccountId: procurementCorrection?.data?.companyBankAccount?.id,
                companySignatoryId: procurementCorrection?.data?.companySignatory?.id,
                companyId: procurementCorrection?.data?.company.id,
                template: '',
                contractGuaranteeValidityPeriod: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
                contractValidityDate: (0, helper_1.transformParamsDate)((0, helper_1.getTomorrow)()),
                deliveryDate: (0, helper_1.transformParamsDate)((0, helper_1.dateToAdd)(1, null, 10))
            };
            //update procurement
            const updateProcurement = await index_1.ProcurementsApi.updateProcurement(procurementCorrectionId, updateData, true);
            if (!updateProcurement.data) {
                if (loading)
                    setIsCorrecting(false);
                return;
            }
            const sendForVerify = await index_1.ProcurementsApi.sendForVerify(procurementCorrectionId);
            if (!sendForVerify) {
                if (loading)
                    setIsCorrecting(false);
                return;
            }
            if (emulatedByPurchase?.id)
                await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
            const emulatedByBudgeting = await useAccountChangeHook.changeUserOrAccount(headOfBudgetingStructuralUnitDev, headOfBudgetingStructuralUnitStaging, budgetAccount);
            await index_1.ProcurementsApi.sendApproval(procurementCorrectionId, true);
            if (emulatedByBudgeting?.id)
                await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
            const emulatedByPurchases = await useAccountChangeHook.changeUserOrAccount(headOfPurchasesStructuralUnitDev, headOfPurchasesStructuralUnitStaging, purchasesDepartmentAccount);
            // attach documents
            const documentData = {
                signedDocumentNumber: (0, helpers_1.getHash)(),
                dateOfConclusion: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
                fileUid: props.uid,
                fileType: 5
            };
            const signedData = {
                fileUid: props.uid,
                fileType: 4
            };
            const attachSignature = await index_1.ProcurementsApi.attachSignature(procurementCorrectionId, documentData, false);
            if (!attachSignature.data) {
                if (loading)
                    setIsSendingCorrectionDocument(false);
                if (emulatedByPurchases?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                return null;
            }
            const sendSignatureDocument = await index_1.ProcurementsApi.sendSignatureDocument(procurementCorrectionId, false);
            if (!sendSignatureDocument.data) {
                if (loading)
                    setIsSendingCorrectionDocument(false);
                if (emulatedByPurchases?.id)
                    await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
                return null;
            }
            const response = await index_1.ProcurementsApi.attachSignedDocument(procurementCorrectionId, signedData, false);
            if (loading)
                setIsCorrecting(false);
            if (emulatedByPurchases?.id)
                await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
            if (response.data !== null) {
                toast.success(translate('procurement_successfully_adjusted', 'შესყიდვა/ხელშეკრულება წარმატებით დაკორექტირდა'));
            }
            else {
                toast.danger(translate('procurement_could_not_adjusted', 'შესყიდვა/ხელშეკრულების წარმატებით ვერ დაკორექტირდა'));
            }
        }
        return null;
    };
    const createCompany = async () => {
        const selectedCompany = await (0, companies_1.getCompaniesForSelect)({ filters: { keyword: `ERP - ${translate('testing_company', 'სატესტო კომპანია')} 0.0.1` } });
        if (selectedCompany.length === 0) {
            const companyData = {
                foreignCompany: false,
                isResident: false,
                identificationNumber: (0, helpers_1.getHash)(),
                name: `ERP - ${translate('testing_company', 'სატესტო კომპანია')} 0.0.1`,
                legalStatus: 1,
                status: 1
            };
            const company = await index_1.CompaniesApi.createCompany(companyData);
            if (company.data === null)
                return;
            const companyId = company.data.id;
            await index_1.CompaniesApi.addSignatory(signatureData, companyId);
            await index_1.CompaniesApi.addBankAccount(bankData, companyId);
            await index_1.CompaniesApi.addAddress(addressData, companyId);
            return await index_1.CompaniesApi.getCompany(companyId);
        }
        const companyId = selectedCompany[0].id;
        return await index_1.CompaniesApi.getCompany(companyId);
    };
    return {
        createProcurement,
        addAndRemoveFakeApprover,
        sendFakeDocument,
        fakeCorrections,
        createFakeProcurementCorrection,
        updateFakeProcurementCorrection,
        addAndRemoveFakeCorrectionApprover,
        sendFakeCorrectionDocument,
        isCreating,
        isAddingAndRemovingApprover,
        isSendingDocument,
        isCorrecting,
        isCreatingCorrection,
        isUpdatingCorrection,
        isAddingAndRemovingCorrectionApprover,
        isSendingCorrectionDocument
    };
};
exports.useFakeProcurement = useFakeProcurement;
