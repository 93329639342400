"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyFormValidation = void 0;
const validates_1 = require("core/helpers/validates");
const CurrencyFormValidation = (values, translate) => {
    const formErrors = {
        name: (0, validates_1.required)(values.name, translate),
        code: (0, validates_1.required)(values.code, translate),
        rate: (0, validates_1.required)(values.rate, translate),
        quantity: (0, validates_1.required)(values.quantity, translate),
        sort: (0, validates_1.required)(values.sort, translate)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.CurrencyFormValidation = CurrencyFormValidation;
