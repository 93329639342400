"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createOrUpdateProjectValidations = void 0;
const validates_1 = require("core/helpers/validates");
const createOrUpdateProjectValidations = (values, oldValues, translate) => {
    const message = translate('once_project_cannot_be_changed', 'როცა პროექტი დასრულებულია არ შეიძლება შეიცვალოს');
    const formErrors = {
        errorResponsibles: !values.isExpired ? (0, validates_1.requiredNotEmptyArray)(values.responsibles, translate) : oldValues.responsibles === values.responsibles ? undefined : message + translate('responsible_person', 'პასუხისმგებელი პირი'),
        name: !values.isExpired ? (0, validates_1.required)(values.name, translate) : oldValues.name === values.name ? undefined : message + translate('field_name', 'დასახელება'),
        fundingSource: !values.isExpired ? (0, validates_1.required)(values.fundingSource, translate) : oldValues.fundingSource === values.fundingSource ? undefined : message + translate('field_funding_source', 'დაფინანსების წყაროები'),
        budgetSource: !values.isExpired ? (0, validates_1.required)(values.budgetSource, translate) : oldValues.budgetSource === values.budgetSource ? undefined : message + translate('menu_budget_sources', 'ბიუჯეტის წყაროები'),
        number: !values.isExpired ? undefined : oldValues.number !== values.number ? message + translate('field_number', 'ნომერი') : undefined,
        donor: !values.isExpired ? undefined : oldValues.donor !== values.donor ? message + translate('field_donor', 'დონორი') : undefined,
        additionalInfo: !values.isExpired ? undefined : oldValues.additionalInfo !== values.additionalInfo ? message + translate('additional_information', 'დამატებითი ინფორმაცია') : undefined,
        startDate: !values.isExpired ? undefined : oldValues.startDate !== values.startDate ? message + translate('field_start_date', 'დაწყების თარიღი') : undefined,
        endDate: !values.isExpired ? undefined : values.startDate ? !values.endDate ? translate('end_date_mandatory_field', 'დასრულების თარიღი სავალდებულო ველია როცა დაწყების თარიღი არის მითითებული.') : undefined : undefined
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.createOrUpdateProjectValidations = createOrUpdateProjectValidations;
