"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const Projects = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: props.projects.length === 0
            ? ((0, jsx_runtime_1.jsx)("div", { className: 'alert alert-warning text-center', children: translate('record_not_found', 'ჩანაწერი ვერ მოიძებნა') }))
            : ((0, jsx_runtime_1.jsxs)("table", { className: 'table', children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "#" }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: translate('field_funding_source', 'დაფინანსების წყარო') }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: translate('field_quantity', 'რაოდენობა') }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: translate('field_price', 'ფასი') })] }) }), (0, jsx_runtime_1.jsx)("tbody", { children: props.projects.map((el, index) => {
                            return ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { scope: 'row', children: index + 1 }), (0, jsx_runtime_1.jsx)("td", { children: el.projectName }), (0, jsx_runtime_1.jsx)("td", { children: el.itemCount }), (0, jsx_runtime_1.jsx)("td", { children: el.totalPrice })] }, el.id.toString()));
                        }) })] })) }));
};
exports.default = Projects;
