"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("api/documents/purchases/procurements/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const helpers_1 = require("../../procurements/helpers");
const store_1 = require("core/store");
const addeditProcurement_1 = require("../../procurements/store/addeditProcurement");
const queryString_1 = require("core/helpers/queryString");
const helpers_2 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const editProcurementCorrection_1 = require("../store/editProcurementCorrection");
const useEditProcurementCorrection = (procurementId, authUserAccountId) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, editProcurementCorrection_1.useProcurementCorrection)();
    const [approvalButton, setApprovalButton] = (0, react_1.useState)(false);
    const [declineButton, setDeclineButton] = (0, react_1.useState)(false);
    const [showDeclineMessage, setShowDeclineMessage] = (0, react_1.useState)(false);
    const [showSuspendMessage, setShowSuspendMessage] = (0, react_1.useState)(false);
    const [suspendButton, setSuspendButton] = (0, react_1.useState)(false);
    const [showSingleApprovalAlert, setShowSingleApprovalAlert] = (0, react_1.useState)(false);
    const [documentLock, setDocumentLock] = (0, react_1.useState)(null);
    const [showRevertMessage, setShowRevertMessage] = (0, react_1.useState)(false);
    const [deletingItems, setDeletingItems] = (0, react_1.useState)([]);
    const [deletingFiles, setDeletingFiles] = (0, react_1.useState)([]);
    const lockInterval = (0, react_1.useRef)();
    const [procurementFileType, setProcurementFileType] = (0, react_1.useState)('');
    const [showDeleteFileAlert, setShowDeleteFileAlert] = (0, react_1.useState)(null);
    const [itemsDirty, setItemsDirty] = (0, react_1.useState)(false);
    const [isDeletingItemsLoading, setIsDeletingItemsLoading] = (0, react_1.useState)(false);
    const [hasItems, setHasItems] = (0, react_1.useState)(false);
    const [procurementsParamsIds, setProcurementsParamsIds] = (0, react_1.useState)({ procurementIds: [], meta: store_1.initialMeta });
    const [buttonNavigate, setButtonNavigate] = (0, react_1.useState)(addeditProcurement_1.initialProcurementNavigate);
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const [showDeleteProcurementCorrectionAlert, setShowDeleteProcurementCorrectionAlert] = (0, react_1.useState)(null);
    const [showActuallyDoneMessage, setShowActuallyDoneMessage] = (0, react_1.useState)(false);
    const getProcurementCorrections = async (procurementId) => {
        dispatch({ type: 'SEND_PROCUREMENT_CORRECTIONS_REQUEST' });
        const procurementCorrections = await index_1.ProcurementCorrectionsApi.getProcurementCorrections(procurementId);
        dispatch({ type: 'DONE_PROCUREMENT_CORRECTIONS_REQUEST', payload: procurementCorrections });
        return procurementCorrections.data;
    };
    const getProcurementCorrection = async (procurementCorrectionId) => {
        dispatch({ type: 'SEND_PROCUREMENT_CORRECTION_REQUEST' });
        const procurementCorrection = await index_1.ProcurementCorrectionsApi.getProcurementCorrection(procurementCorrectionId, false);
        dispatch({ type: 'DONE_PROCUREMENT_CORRECTION_REQUEST', payload: procurementCorrection });
    };
    const getProcurementCorrectionsTemplate = async (correctionId) => {
        dispatch({ type: 'SEND_TEMPLATE_REQUEST' });
        const contentData = await index_1.ProcurementsApi.getProcurementTemplate(correctionId);
        dispatch({ type: 'DONE_TEMPLATE_REQUEST', payload: contentData });
    };
    const updateProcurementTemplate = async (values, id) => {
        const params = (0, helpers_1.transformProcurementTemplateParams)(values);
        dispatch({ type: 'SEND_TEMPLATE_UPDATE' });
        const contentData = await index_1.ProcurementsApi.updateProcurementTemplate(id, params);
        dispatch({ type: 'DONE_TEMPLATE_UPDATE', payload: contentData });
        if (state.data.currentVersion.status !== types_1.ProcurementStatuses.DRAFT && contentData.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getProcurementCorrection(state.data.currentVersion.id).then();
        return contentData;
    };
    const generateProcurementTemplate = async (id) => {
        dispatch({ type: 'SEND_TEMPLATE_GENERATE' });
        const procurement = await index_1.ProcurementsApi.generateProcurementTemplate(id);
        dispatch({ type: 'DONE_TEMPLATE_GENERATE', payload: procurement });
        return procurement;
    };
    const sendForVerify = async (procurementCorrectionId) => {
        setShowSingleApprovalAlert(false);
        dispatch({ type: 'SEND_PROCUREMENT_CORRECTION_VERIFY' });
        const response = await index_1.ProcurementsApi.sendForVerify(procurementCorrectionId);
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            await getProcurementCorrections(procurementId);
        dispatch({ type: 'DONE_PROCUREMENT_CORRECTION_VERIFY' });
        return response;
    };
    const deleteProcurementItem = async (ids) => {
        setIsDeletingItemsLoading(true);
        const response = await index_1.ProcurementsApi.deleteProcurementItem(state.data.currentVersion.id, { ids: ids });
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            setDeletingItems([]);
        setIsDeletingItemsLoading(false);
        return response;
    };
    const sendApproval = async (correctionProcurementId) => {
        dispatch({ type: 'SEND_PROCUREMENT_CORRECTION_APPROVE' });
        const response = await index_1.ProcurementsApi.sendApproval(correctionProcurementId, false);
        if (response.data !== null) {
            const procurementCorrections = await index_1.ProcurementCorrectionsApi.getProcurementCorrections(procurementId);
            dispatch({ type: 'DONE_PROCUREMENT_CORRECTIONS_REQUEST', payload: procurementCorrections });
        }
        dispatch({ type: 'DONE_PROCUREMENT_CORRECTION_APPROVE' });
        return response;
    };
    const declineProcurement = async (message, correctionProcurementId) => {
        dispatch({ type: 'SEND_PROCUREMENT_CORRECTION_DECLINE' });
        setShowDeclineMessage(false);
        const response = await index_1.ProcurementsApi.declineProcurement(message, correctionProcurementId, false);
        if (response.data !== null) {
            const procurementCorrections = await index_1.ProcurementCorrectionsApi.getProcurementCorrections(procurementId);
            dispatch({ type: 'DONE_PROCUREMENT_CORRECTIONS_REQUEST', payload: procurementCorrections });
        }
        dispatch({ type: 'DONE_PROCUREMENT_CORRECTION_DECLINE' });
        return response;
    };
    const suspendProcurement = async (message, correctionProcurementId) => {
        dispatch({ type: 'SEND_PROCUREMENT_CORRECTION_SUSPEND' });
        setShowSuspendMessage(false);
        const response = await index_1.ProcurementsApi.suspendProcurement(message, correctionProcurementId, false);
        if (response.data !== null) {
            const procurementCorrections = await index_1.ProcurementCorrectionsApi.getProcurementCorrections(procurementId);
            dispatch({ type: 'DONE_PROCUREMENT_CORRECTIONS_REQUEST', payload: procurementCorrections });
        }
        dispatch({ type: 'DONE_PROCUREMENT_CORRECTION_SUSPEND' });
        return response;
    };
    const actuallyDoneProcurement = async (message, correctionProcurementId) => {
        dispatch({ type: 'SEND_PROCUREMENT_CORRECTION_ACTUALLY_DONE' });
        setShowActuallyDoneMessage(false);
        const response = await index_1.ProcurementsApi.actuallyDoneProcurement(message, correctionProcurementId, false);
        if (response.data !== null) {
            const procurementCorrections = await index_1.ProcurementCorrectionsApi.getProcurementCorrections(procurementId);
            dispatch({ type: 'DONE_PROCUREMENT_CORRECTIONS_REQUEST', payload: procurementCorrections });
        }
        dispatch({ type: 'DONE_PROCUREMENT_CORRECTION_ACTUALLY_DONE' });
        return { errors: response.errors, status: response.status };
    };
    const getProcurement = async () => {
        dispatch({ type: 'SEND_PROCUREMENT_REQUEST' });
        const procurement = await index_1.ProcurementsApi.getProcurement(procurementId, true);
        dispatch({ type: 'DONE_PROCUREMENT_REQUEST', payload: procurement });
    };
    const updateProcurement = async (values, correctionProcurementId) => {
        const params = (0, helpers_2.transformUpdateProcurementCorrectionParams)(values);
        dispatch({ type: 'SEND_PROCUREMENT_CORRECTION_UPDATE' });
        const response = await index_1.ProcurementCorrectionsApi.updateProcurementCorrection(correctionProcurementId, params);
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            const procurementCorrections = await index_1.ProcurementCorrectionsApi.getProcurementCorrections(procurementId);
            await getProcurementCorrectionsTemplate(correctionProcurementId);
            dispatch({ type: 'DONE_PROCUREMENT_CORRECTIONS_REQUEST', payload: procurementCorrections });
            if (params.signedDocumentNumber)
                getProcurement().then();
        }
        dispatch({ type: 'DONE_PROCUREMENT_CORRECTION_UPDATE' });
        return response;
    };
    const updateLetterOrSubmission = async (values) => {
        dispatch({ type: 'UPDATE_LETTER_OR_SUBMISSION', payload: values });
    };
    const changeCurrentVersion = (index) => { dispatch({ type: 'CHANGE_CURRENT_VERSION', payload: index }); };
    const loadOriginal = () => { dispatch({ type: 'LOAD_ORIGINAL_VERSION' }); };
    const documentLockIn = async () => {
        const currentVersion = state.data.currentVersion;
        if (!currentVersion)
            return { status: apiGlobalTypes_1.ResponseStatuses.UNEXPECTED, errors: [{ name: 'currentVersion error', message: 'procurement is not current version' }] };
        const lockInParams = { moduleName: currentVersion.moduleType, moduleId: currentVersion.id };
        dispatch({ type: 'SEND_PROCUREMENT_CORRECTION_LOCK' });
        const lockData = await index_1.LockApi.lockIn(lockInParams);
        if (lockData.data !== null)
            setDocumentLock(lockData.data);
        dispatch({ type: 'DONE_PROCUREMENT_CORRECTION_LOCK' });
        if (!lockInterval.current && lockData.data !== null && lockData.data.timeDistance) {
            lockInterval.current = setInterval(() => { documentLockIn(); }, (lockData.data.timeDistance) - 10000);
        }
        return { errors: lockData.errors, status: lockData.status };
    };
    const documentLockOut = async () => {
        clearInterval(lockInterval.current);
        lockInterval.current = undefined;
        const currentVersion = state.data.currentVersion;
        if (!currentVersion)
            return { status: apiGlobalTypes_1.ResponseStatuses.UNEXPECTED, errors: [{ name: 'currentVersion error', message: 'procurement is not current version' }] };
        const lockInParams = { moduleName: currentVersion.moduleType, moduleId: currentVersion.id };
        dispatch({ type: 'SEND_PROCUREMENT_CORRECTION_LOCKOUT' });
        const lockResponse = await index_1.LockApi.lockOut(lockInParams);
        if (lockResponse.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            setDocumentLock(null);
        dispatch({ type: 'DONE_PROCUREMENT_CORRECTION_LOCKOUT' });
        return { status: lockResponse.status, errors: lockResponse.errors };
    };
    const revertProcurement = async (revertComment) => {
        setShowRevertMessage(false);
        dispatch({ type: 'SEND_PROCUREMENT_CORRECTION_REVERT' });
        const response = await index_1.ProcurementsApi.revertProcurement(state.data.currentVersion.id, { revertComment }, false);
        if (response.errors === null)
            getProcurementCorrections(procurementId).then();
        dispatch({ type: 'DONE_PROCUREMENT_CORRECTION_REVERT' });
        return { status: response.status, errors: response.errors };
    };
    const addUpdateTreasuryBudgetArticle = async (procurementItemId, treasuryBudgetArticleId) => {
        dispatch({ type: 'SEND_UPDATE_TREASURY_BUDGET_ARTICLE_REQUEST' });
        const response = await index_1.ProcurementsApi.addUpdateTreasuryBudgetArticle({ items: [{ id: procurementItemId, treasuryBudgetArticleId }] });
        dispatch({ type: 'DONE_UPDATE_TREASURY_BUDGET_ARTICLE_REQUEST' });
        return response;
    };
    const addUpdateTreasuryProject = async (procurementItemId, treasuryProjectId) => {
        dispatch({ type: 'SEND_UPDATE_TREASURY_PROJECT_REQUEST' });
        const response = await index_1.ProcurementsApi.addUpdateTreasuryBudgetArticle({ items: [{ id: procurementItemId, treasuryProjectId }] });
        dispatch({ type: 'DONE_UPDATE_TREASURY_PROJECT_REQUEST' });
        return response;
    };
    const attachSignature = async (values) => {
        const params = (0, helpers_1.transformSignatureParams)(values);
        dispatch({ type: 'SEND_ATTACH_SIGNATURE' });
        const procurement = await index_1.ProcurementsApi.attachSignature(values.id, params, false);
        dispatch({ type: 'DONE_ATTACH_SIGNATURE', payload: procurement });
        return procurement;
    };
    const attachSignedDocument = async (values) => {
        const params = (0, helpers_1.transformSignatureDocument)(values);
        dispatch({ type: 'SEND_ATTACH_SIGNATURE_DOCUMENT' });
        const procurement = await index_1.ProcurementsApi.attachSignedDocument(values.id, params, false);
        if (procurement.data !== null)
            await getProcurementCorrections(procurementId);
        dispatch({ type: 'DONE_ATTACH_SIGNATURE_DOCUMENT', payload: procurement });
        return procurement;
    };
    const sendSignatureDocument = async (procurementCorrectionId) => {
        dispatch({ type: 'SEND_SIGNATURE_DOCUMENT' });
        const procurement = await index_1.ProcurementsApi.sendSignatureDocument(procurementCorrectionId, false);
        dispatch({ type: 'DONE_SIGNATURE_DOCUMENT', payload: procurement });
        return procurement;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const deleteProcurementFile = async (id) => {
        setDeletingFiles(prev => [...prev, id]);
        const procurementFile = await index_1.ProcurementsApi.deleteProcurementFile(state.data.currentVersion.id, { fileUids: [id] });
        setDeletingFiles(prev => prev.filter(el => el !== id));
        return { errors: procurementFile.errors, status: procurementFile.status };
    };
    const getProcurementIds = async (values) => {
        const onlyMine = await localStorage.getItem('procurementOnlyMine') || undefined;
        const procurementParams = (0, helpers_1.transformProcurementParamsToFilterParams)({ ...values, onlyMine });
        const itemsParams = (0, helpers_1.getItemsParamsFromUrl)(location.search);
        const procurements = await index_1.ProcurementsApi.getProcurements(procurementParams);
        if (procurements.data !== null && procurements.meta) {
            const responseIds = { procurementIds: procurements.data.map(el => {
                    return {
                        id: el.id,
                        status: el.status
                    };
                }), meta: procurements.meta };
            const searchParams = (0, queryString_1.stringify)({ ...(0, helpers_1.transformFilterParamsToUrl)(values), ...itemsParams });
            setListingParams(searchParams || '?');
            setProcurementsParamsIds(responseIds);
            return responseIds;
        }
        return null;
    };
    const nextProcurement = async () => {
        setButtonNavigate(prevState => ({ ...prevState, next: true, isLoading: true }));
        const currentIndex = procurementsParamsIds.procurementIds.findIndex(el => el.id === procurementId);
        const nextId = procurementsParamsIds.procurementIds[currentIndex + 1];
        const { pagination } = procurementsParamsIds.meta;
        const params = (0, helpers_1.transformFilterParamsToUrl)((0, helpers_1.transformUrlToFilterParams)(location.search));
        if (nextId) {
            const CorrectionOrEdit = nextId.status === types_1.ProcurementStatuses.CORRECTED
                || nextId.status === types_1.ProcurementStatuses.CORRECTION_PROCESS
                ? '/corrections'
                : '/edit';
            const itemsParams = (0, helpers_1.getItemsParamsFromUrl)(location.search);
            navigate('/documents/purchases/procurements/' + nextId.id + CorrectionOrEdit + (0, queryString_1.stringify)({ ...params, ...itemsParams }));
            const lastElement = pagination.currentPage === pagination.totalPages && procurementsParamsIds.procurementIds.length === currentIndex + 2;
            setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === pagination.totalPages) {
                setButtonNavigate(prevState => ({ ...prevState, next: false, lastElement: true, isLoading: false }));
                return;
            }
            const formValue = (0, helpers_1.transformUrlToFilterParams)(location.search);
            const response = await getProcurementIds({ ...formValue, page: pagination.currentPage + 1 });
            if (response && response.procurementIds[0]) {
                const CorrectionOrEdit = response.procurementIds[0].status === types_1.ProcurementStatuses.CORRECTED
                    || response.procurementIds[0].status === types_1.ProcurementStatuses.CORRECTION_PROCESS
                    ? '/corrections'
                    : '/edit';
                formValue.page = response.meta.pagination.currentPage;
                const itemsParams = (0, helpers_1.getItemsParamsFromUrl)(location.search);
                const urlParams = { ...(0, helpers_1.transformFilterParamsToUrl)(formValue), page: response.meta.pagination.currentPage, ...itemsParams };
                navigate('/documents/purchases/procurements/' + response.procurementIds[0].id + CorrectionOrEdit + (0, queryString_1.stringify)(urlParams));
                const lastElement = response.meta.pagination.currentPage === response.meta.pagination.totalPages && response.procurementIds.length === 1;
                setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
            }
        }
    };
    const previousProcurement = async () => {
        setButtonNavigate(prevState => ({ ...prevState, previous: true, isLoading: true }));
        const currentIndex = procurementsParamsIds.procurementIds.findIndex(el => el.id === procurementId);
        const prevId = procurementsParamsIds.procurementIds[currentIndex - 1];
        const { pagination } = procurementsParamsIds.meta;
        const params = (0, helpers_1.transformFilterParamsToUrl)((0, helpers_1.transformUrlToFilterParams)(location.search));
        if (prevId) {
            const CorrectionOrEdit = prevId.status === types_1.ProcurementStatuses.CORRECTED
                || prevId.status === types_1.ProcurementStatuses.CORRECTION_PROCESS
                ? '/corrections'
                : '/edit';
            const itemsParams = (0, helpers_1.getItemsParamsFromUrl)(location.search);
            navigate('/documents/purchases/procurements/' + prevId.id + CorrectionOrEdit + (0, queryString_1.stringify)({ ...params, ...itemsParams }));
            const firstElement = pagination.currentPage === 1 && currentIndex === 1;
            setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === 1) {
                setButtonNavigate(prevState => ({ ...prevState, previous: false, firstElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformUrlToFilterParams)(location.search);
            const response = await getProcurementIds({ ...formValues, page: pagination.currentPage - 1 });
            if (response) {
                const previousProcurement = response.procurementIds[response.procurementIds.length - 1];
                if (previousProcurement) {
                    const CorrectionOrEdit = previousProcurement.status === types_1.ProcurementStatuses.CORRECTED
                        || previousProcurement.status === types_1.ProcurementStatuses.CORRECTION_PROCESS
                        ? '/corrections'
                        : '/edit';
                    formValues.page = response.meta.pagination.currentPage;
                    const itemsParams = (0, helpers_1.getItemsParamsFromUrl)(location.search);
                    const urlParams = {
                        ...(0, helpers_1.transformFilterParamsToUrl)(formValues),
                        page: response.meta.pagination.currentPage, ...itemsParams
                    };
                    navigate('/documents/purchases/procurements/' + previousProcurement.id + CorrectionOrEdit
                        + (0, queryString_1.stringify)(urlParams));
                    const firstElement = response.meta.pagination.currentPage === 1 && currentIndex === 1;
                    setButtonNavigate(prevState => ({
                        ...prevState,
                        previous: false,
                        lastElement: false,
                        firstElement,
                        isLoading: false
                    }));
                }
            }
        }
    };
    const deleteProcurementCorrection = async () => {
        const deleteStatus = await index_1.ProcurementCorrectionsApi.deleteProcurementCorrection(showDeleteProcurementCorrectionAlert);
        getProcurementCorrections(procurementId).then();
        return { status: deleteStatus.status, errors: deleteStatus.errors };
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.transformUrlToFilterParams)(location.search);
        params.onlyMine = localStorage.getItem('procurementOnlyMine') || undefined;
        getProcurementIds(params).then(response => {
            if (response) {
                const { currentPage, totalPages } = response.meta.pagination;
                const procurementIndex = response.procurementIds.findIndex(el => el.id === procurementId);
                if (procurementIndex === 0 && currentPage === 1)
                    setButtonNavigate(prevState => ({ ...prevState, firstElement: true }));
                if (currentPage === totalPages && procurementIndex === response.procurementIds.length - 1) {
                    setButtonNavigate(prevState => ({ ...prevState, lastElement: true }));
                }
            }
        });
        getProcurementCorrections(procurementId).then(data => {
            if (params.correctionId && data) {
                data.versions?.forEach((el, index) => {
                    if (el.id === params.correctionId)
                        return changeCurrentVersion(index);
                });
            }
            getProcurement().then();
        });
    }, [procurementId]);
    const currentVersion = state.data.currentVersion;
    (0, react_1.useEffect)(() => {
        if (authUserAccountId && currentVersion && currentVersion.approvers) {
            const approvalUser = currentVersion.approvers.some((el) => el.accountId === authUserAccountId && el.status !== types_1.ApproverStatus.APPROVED && !el.deletedAt);
            const declineUser = currentVersion.approvers.some((el) => el.accountId === authUserAccountId && el.status !== types_1.ApproverStatus.DECLINED && !el.deletedAt);
            const suspendUser = currentVersion.approvers.some((el) => el.accountId === authUserAccountId && el.status !== types_1.ApproverStatus.SUSPENDED && !el.deletedAt);
            if ((!declineUser && declineButton) || currentVersion.status !== types_1.ProcurementStatuses.APPROVAL_PROGRESS)
                setDeclineButton(false);
            if ((!approvalUser && approvalButton) || currentVersion.status !== types_1.ProcurementStatuses.APPROVAL_PROGRESS)
                setApprovalButton(false);
            if ((!suspendUser && suspendButton) || currentVersion.status !== types_1.ProcurementStatuses.APPROVAL_PROGRESS)
                setSuspendButton(false);
            if (currentVersion.status === types_1.ProcurementStatuses.APPROVAL_PROGRESS) {
                setApprovalButton(approvalUser);
                setDeclineButton(declineUser);
                setSuspendButton(suspendUser);
            }
        }
    }, [currentVersion, authUserAccountId]);
    (0, react_1.useEffect)(() => {
        return () => {
            clearInterval(lockInterval.current);
            const currentVersion = state.data.currentVersion;
            if (lockInterval.current && documentLock?.status) {
                lockInterval.current = undefined;
                const lockInParams = { moduleName: currentVersion.moduleType, moduleId: currentVersion.id };
                index_1.LockApi.lockOut(lockInParams).then();
                setDocumentLock(null);
            }
        };
    }, [authUserAccountId, documentLock?.status, state.data.currentVersion.id, state.data.currentVersion.status]);
    return {
        state,
        sendForVerify,
        sendApproval,
        showRevertMessage,
        documentLockIn,
        documentLockOut,
        addUpdateTreasuryProject,
        addUpdateTreasuryBudgetArticle,
        setShowRevertMessage,
        revertProcurement,
        documentLock,
        setShowDeclineMessage,
        approvalButton,
        declineProcurement,
        showDeclineMessage,
        declineButton,
        updateProcurement,
        changeCurrentVersion,
        loadOriginal,
        getProcurementCorrections,
        deleteProcurementItem,
        updateLetterOrSubmission,
        deletingItems,
        attachSignature,
        attachSignedDocument,
        sendSignatureDocument,
        showSingleApprovalAlert,
        setShowSingleApprovalAlert,
        closeErrorMessage,
        deleteProcurementFile,
        procurementFileType,
        setProcurementFileType,
        deletingFiles,
        setShowDeleteFileAlert,
        showDeleteFileAlert,
        dispatch,
        setDeletingItems,
        isDeletingItemsLoading,
        itemsDirty,
        setItemsDirty,
        getProcurement,
        hasItems,
        setHasItems,
        buttonNavigate,
        listingParams,
        nextProcurement,
        previousProcurement,
        showDeleteProcurementCorrectionAlert,
        setShowDeleteProcurementCorrectionAlert,
        deleteProcurementCorrection,
        suspendProcurement,
        setShowSuspendMessage,
        suspendButton,
        showSuspendMessage,
        setShowActuallyDoneMessage,
        showActuallyDoneMessage,
        actuallyDoneProcurement,
        updateProcurementTemplate,
        getProcurementCorrectionsTemplate,
        generateProcurementTemplate
    };
};
exports.default = useEditProcurementCorrection;
