"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrectionFormValidation = void 0;
const validates_1 = require("core/helpers/validates");
const CorrectionFormValidation = (values, translate) => {
    const formErrors = {
        number: (0, validates_1.required)(values.number, translate),
        publicationDate: (0, validates_1.required)(values.publicationDate, translate),
        name: (0, validates_1.required)(values.name, translate),
        fileError: (0, validates_1.requiredNotEmptyArray)(values.files, translate),
        articlesError: (0, validates_1.requiredNotEmptyArray)(values.correction, translate)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.CorrectionFormValidation = CorrectionFormValidation;
