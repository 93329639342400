"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const ReadOnlyCompanySignatory = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    return ((0, jsx_runtime_1.jsx)("div", { className: 'row', children: (0, jsx_runtime_1.jsx)("div", { className: 'col-md-11 mx-auto', children: (0, jsx_runtime_1.jsxs)("table", { className: 'table table-hover', children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { style: { maxWidth: 40 }, children: "#" }), (0, jsx_runtime_1.jsx)("th", { children: translate('field_firstname', 'სახელი') }), (0, jsx_runtime_1.jsx)("th", { children: translate('field_lastname', 'გვარი') }), (0, jsx_runtime_1.jsx)("th", { children: translate('field_position', 'თანამდებობა') }), (0, jsx_runtime_1.jsx)("th", { children: translate('field_phone_number', 'ტელეფონის ნომერი') })] }) }), (0, jsx_runtime_1.jsx)("tbody", { children: props.data.signatories?.map((el, index) => {
                            return ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("td", { children: index + 1 }), (0, jsx_runtime_1.jsx)("td", { children: el.firstname }), (0, jsx_runtime_1.jsx)("td", { children: el.lastname }), (0, jsx_runtime_1.jsx)("td", { children: el.position }), (0, jsx_runtime_1.jsx)("td", { children: el.phones.map(element => ((0, jsx_runtime_1.jsx)("div", { children: element.phone }, element.id))) })] }, index));
                        }) })] }) }) }));
};
exports.default = ReadOnlyCompanySignatory;
