"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPurchaseSubjectsForSelect = exports.getPurchaseSubject = exports.getPurchaseSubjectsCharacteristics = exports.updatePurchaseSubject = exports.createUnverifiedPurchaseSubject = exports.createPurchaseSubject = exports.deletePurchaseSubject = exports.getPurchaseSubjectCollection = exports.getPurchaseSubjects = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../privateRequest"));
const parsers_1 = require("./parsers");
const getPurchaseSubjects = async (params) => {
    const response = await Api.get('references/inventory/configurations', params);
    return (0, parsers_1.parsePurchaseSubjects)(response);
};
exports.getPurchaseSubjects = getPurchaseSubjects;
const getPurchaseSubjectCollection = async (params) => {
    const response = await Api.get('references/inventory/configurations', { ...params, perPage: 10 });
    return (0, parsers_1.parsePurchaseSubjectCollection)(response);
};
exports.getPurchaseSubjectCollection = getPurchaseSubjectCollection;
const deletePurchaseSubject = async (id) => {
    return await Api.deleteItem('references/inventory/configurations/' + id);
};
exports.deletePurchaseSubject = deletePurchaseSubject;
const createPurchaseSubject = async (body) => {
    const response = await Api.post('references/inventory/configurations', body);
    return (0, parsers_1.parsePurchaseSubject)(response);
};
exports.createPurchaseSubject = createPurchaseSubject;
const createUnverifiedPurchaseSubject = async (body) => {
    const response = await Api.post('references/inventory/configurations/add-configuration-item', body);
    return (0, parsers_1.parsePurchaseSubject)(response);
};
exports.createUnverifiedPurchaseSubject = createUnverifiedPurchaseSubject;
const updatePurchaseSubject = async (id, params) => {
    const response = await Api.patch('references/inventory/configurations/' + id, params);
    return (0, parsers_1.parsePurchaseSubject)(response);
};
exports.updatePurchaseSubject = updatePurchaseSubject;
const getPurchaseSubjectsCharacteristics = async (id) => {
    const response = await Api.get(`references/inventory/configurations/${id}/items-with-characteristics`, {});
    return (0, parsers_1.parsePurchaseSubjectCharacteristics)(response);
};
exports.getPurchaseSubjectsCharacteristics = getPurchaseSubjectsCharacteristics;
const getPurchaseSubject = async (id) => {
    const response = await Api.get('references/inventory/configurations/' + id);
    return (0, parsers_1.parsePurchaseSubject)(response);
};
exports.getPurchaseSubject = getPurchaseSubject;
const getPurchaseSubjectsForSelect = async (params) => {
    const response = await Api.get('references/inventory/configurations', params);
    return (0, parsers_1.parsePurchaseSubjectsForSelect)(response);
};
exports.getPurchaseSubjectsForSelect = getPurchaseSubjectsForSelect;
