"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const useApprovers = (procurementId, getProcurement, procurementCorrectionId) => {
    const [structuralUnits, setStructuralUnits] = (0, react_1.useState)([]);
    const [deletingApprover, setDeletingApprover] = (0, react_1.useState)({ id: null, show: false });
    const [newApproval, setNewApproval] = (0, react_1.useState)({ employee: null, position: null });
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const state = { deletingApprover, newApproval, structuralUnits, isLoading };
    const setApproval = (name, value) => {
        setNewApproval(prevState => ({ ...prevState, [name]: value }));
        if (name === 'employee') {
            if (value === null)
                return setStructuralUnits([]);
            setStructuralUnits(value.structuralUnits || []);
        }
    };
    const deleteApprover = async (deleteComment) => {
        setDeletingApprover(prevState => ({ id: prevState.id, show: false }));
        const response = await index_1.ProcurementsApi.deleteApprover(deletingApprover.id, { deleteComment });
        if (response.status)
            getProcurement(procurementId);
        setDeletingApprover({ id: null, show: false });
        return { errors: response.errors, status: response.status };
    };
    const addApprover = async () => {
        if (!newApproval.employee?.id || !newApproval.position?.accountId)
            return { errors: null, status: apiGlobalTypes_1.ResponseStatuses.SUCCESS };
        // const approvedParams = {  userId: newApproval.employee.id, structuralUnitId: newApproval.position.id }
        const approvedParams = { accountId: newApproval.position.accountId };
        setIsLoading(true);
        const response = await index_1.ProcurementsApi.addApprover(procurementCorrectionId || procurementId, approvedParams);
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            getProcurement(procurementId);
            setNewApproval({ employee: null, position: null });
        }
        setIsLoading(false);
        return { errors: response.errors, status: response.status };
    };
    return { addApprover, setApproval, setDeletingApprover, deleteApprover, state };
};
exports.default = useApprovers;
