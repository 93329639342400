"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCalculateItems = void 0;
const procurmentCalculateItems_1 = require("../store/procurmentCalculateItems");
const index_1 = require("api/index");
const react_1 = require("react");
const useCalculateItems = (id) => {
    const [state, dispatch] = (0, procurmentCalculateItems_1.useProcurementCalculateItemsReducer)();
    const getProcurementCalculateItems = async () => {
        dispatch({ type: 'SEND_PROCUREMENT_CALCULATE_ITEMS_REQUEST' });
        const items = await index_1.ProcurementsApi.getProcurementCalculateItems(id);
        dispatch({ type: 'DONE_PROCUREMENT_CALCULATE_ITEMS_REQUEST', payload: items });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        getProcurementCalculateItems().then();
    }, []);
    return { state, closeErrorMessage };
};
exports.useCalculateItems = useCalculateItems;
