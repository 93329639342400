"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.invoiceFormValidation = void 0;
const types_1 = require("api/documents/purchases/invoices/types");
const validates_1 = require("core/helpers/validates");
const invoiceFormValidation = (values, translate) => {
    if (values === null)
        return {};
    const formErrors = {
        procurement: (0, validates_1.required)(values.procurement, translate),
        company: (0, validates_1.required)(values.company, translate),
        companySignatory: values.invoiceType === types_1.InvoiceType.DEFAULT ? (0, validates_1.required)(values.companySignatory, translate) : undefined,
        signatories: values.invoiceType === types_1.InvoiceType.DEFAULT ? (0, validates_1.requiredNotEmptyArray)(values.signatories, translate) : undefined,
        warehouse: values.invoiceType === types_1.InvoiceType.DEFAULT && values.procurement?.contractType.label !== 'მომსახურება' ? (0, validates_1.required)(values.warehouse, translate) : undefined,
        receiptDate: values.invoiceType === types_1.InvoiceType.DEFAULT ? (0, validates_1.required)(values.receiptDate, translate) : undefined,
        creationDate: values.invoiceType === types_1.InvoiceType.DEFAULT ? (0, validates_1.required)(values.creationDate, translate) : undefined,
        deliveryPlace: values.invoiceType === types_1.InvoiceType.DEFAULT ? (0, validates_1.required)(values.deliveryPlace, translate) : undefined,
        fine: values.fineAccount ? (0, validates_1.requiredPositiveNumber)(values.fine, translate) : undefined,
        fineAccount: values.fine ? (0, validates_1.required)(values.fineAccount, translate) : undefined,
        incomeType: values.fineAccount ? (0, validates_1.required)(values.incomeType, translate) : undefined,
        filesError: values.status === types_1.InvoiceStatuses.CONFIRMED && values.invoiceType !== types_1.InvoiceType.ONLY_FILE
            ? (0, validates_1.requiredNotEmptyArray)(values.files, translate)
            : undefined,
        invoiceFilesError: values.invoiceType === types_1.InvoiceType.ONLY_FILE ? (0, validates_1.requiredNotEmptyArray)(values.invoiceFile, translate) : undefined,
        NotifyDepartmentError: values.status === types_1.InvoiceStatuses.CONFIRMED ? !values.sentToAccounting && !values.sentToWarehouse ? 'სავალდებულო ველი' : undefined : undefined
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.invoiceFormValidation = invoiceFormValidation;
