"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const api_1 = require("../../../../api");
const myFiles_1 = require("../store/myFiles");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_router_dom_1 = require("react-router-dom");
const useMyFiles = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, myFiles_1.useMyFilesReducer)();
    const [openedFilePreview, setOpenedFilePreview] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const navigatePagination = (page, values) => {
        getMyFiles({ ...values, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        getMyFiles({ ...values, perPage, page: 1 }).then();
    };
    const filter = (name, value, filterValues) => {
        getMyFiles({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getMyFiles);
    };
    const getMyFiles = async (values) => {
        dispatch({ type: 'SEND_MY_FILES_REQUEST' });
        const filterParams = (0, helpers_1.transformMyFilesParamsToFilterParams)(values);
        const myFiles = await api_1.MyFilesApi.getMyFiles(filterParams);
        if (myFiles.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_1.transformMyFilesFilterParamsToUrl)(values)));
        dispatch({ type: 'DONE_MY_FILES_REQUEST', payload: myFiles });
        if (isMounted?.current) {
            navigate('/personal-documents/my-files' + (0, queryString_1.stringify)((0, helpers_1.transformMyFilesFilterParamsToUrl)(values)), { replace: true });
            dispatch({ type: 'DONE_MY_FILES_REQUEST', payload: myFiles });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToMyFilesFilterParams)(location.search);
        dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params });
        const isAnyFilterValue = Object.entries(params).some(([key, value]) => {
            if (key === 'page' || key === 'perPage')
                return false;
            return !!value;
        });
        if (isAnyFilterValue)
            setShowFilter(true);
        getMyFiles(params).then();
    };
    const transferFile = async (uid) => {
        dispatch({ type: 'SEND_TRANSFORM_FILE_REQUEST', payload: uid });
        const myFiles = await api_1.MyFilesApi.transferFile(uid);
        dispatch({ type: 'DONE_TRANSFORM_FILE_REQUEST', payload: { fileId: uid, file: myFiles } });
        return myFiles;
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        if (props.accountId)
            initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, [props.accountId]);
    return {
        state,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        getMyFiles,
        setOpenedFilePreview,
        openedFilePreview,
        transferFile,
        linkParams,
        setShowFilter,
        showFilter,
        filter
    };
};
exports.default = useMyFiles;
