"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainProvider = exports.MainContext = exports.useMain = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
function useMain() {
    return (0, react_1.useContext)(exports.MainContext);
}
exports.useMain = useMain;
const initialMainContext = {
    googleAuthWarn: false,
    setGoogleAuthWarn: () => ({}),
    mobileCollapsed: false,
    setMobileCollapsed: () => ({}),
    temporaryUnavailable: false,
    setTemporaryUnavailable: () => ({}),
    isDark: false,
    setIsDark: () => ({})
};
exports.MainContext = (0, react_1.createContext)(initialMainContext);
const MainProvider = ({ children }) => {
    const [mobileCollapsed, setMobileCollapsed] = (0, react_1.useState)(false);
    const [googleAuthWarn, setGoogleAuthWarn] = (0, react_1.useState)(false);
    const [isDark, setIsDark] = (0, react_1.useState)(false);
    const [temporaryUnavailable, setTemporaryUnavailable] = (0, react_1.useState)(false);
    const getLocalstorage = async () => {
        const theme = localStorage.getItem('theme');
        if (theme === 'dark') {
            document.body.classList.add('dark-theme');
            setIsDark(true);
        }
        else {
            document.body.classList.remove('dark-theme');
        }
    };
    (0, react_1.useEffect)(() => {
        getLocalstorage().then();
    }, []);
    (0, react_1.useEffect)(() => {
        isDark ? document.body.classList.add('dark-theme') : document.body.classList.remove('dark-theme');
        localStorage.setItem('theme', isDark ? 'dark' : 'light');
    }, [isDark]);
    const value = {
        mobileCollapsed,
        setMobileCollapsed,
        googleAuthWarn,
        setGoogleAuthWarn,
        isDark,
        setIsDark,
        setTemporaryUnavailable,
        temporaryUnavailable
    };
    return (0, jsx_runtime_1.jsx)(exports.MainContext.Provider, { value: value, children: children });
};
exports.MainProvider = MainProvider;
