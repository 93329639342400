"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.throwException = exports.errorObject = exports.getErrorMessages = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const react_1 = require("@sentry/react");
// export const getErrorMessage = (response: ErrorResponse, status: number): Array<Error> => {
//   switch (status) {
//   case 422:
//     return response.errors.map(el => ({ name: response.message, message: el }))
//   }
//   return [{ name: 'unknown', message: 'something went wrong' }]
// }
const getErrorMessages = async (response) => {
    const responseErrors = await response.json();
    switch (response.status) {
        case apiGlobalTypes_1.ResponseStatuses.UNPROCESSABLE_ENTITY:
            return (() => {
                const errors = [];
                if (responseErrors.message && !responseErrors.errors) {
                    errors.push({ name: 'message', message: responseErrors.message });
                    return errors;
                }
                if (responseErrors) {
                    Object
                        .keys(responseErrors.errors)
                        .forEach(el => {
                        responseErrors.errors[el].forEach(message => errors.push({ name: el, message: message }));
                    });
                }
                return errors;
            })();
        case apiGlobalTypes_1.ResponseStatuses.NOT_FOUND:
            return [{ name: 'not found', message: 'მისამართი არ მოიძებნა' }];
        case apiGlobalTypes_1.ResponseStatuses.TOO_LARGE:
            return [{ name: 'too large', message: 'დიდი ზომის ფაილი. დასაშვები ფაილის ზომა არის 20 მგ' }];
        case apiGlobalTypes_1.ResponseStatuses.METHOD_NOT_ALLOWED:
            return [{ name: 'not allowed', message: responseErrors.message }];
        case apiGlobalTypes_1.ResponseStatuses.TOO_MANY:
            return [{ name: 'too many', message: responseErrors.message }];
        case apiGlobalTypes_1.ResponseStatuses.FORBIDDEN:
            return [{ name: 'forbidden', message: responseErrors.message }];
        case apiGlobalTypes_1.ResponseStatuses.CONFLICT:
            return [{ name: 'conflict', message: responseErrors.message }];
        case apiGlobalTypes_1.ResponseStatuses.SERVER_ERROR:
            return [{ name: 'server error', message: 'status-500 something went wrong' }];
        case apiGlobalTypes_1.ResponseStatuses.APPLICATION_UNAVAILABLE:
            return [{ name: 'application unavailable', message: 'დროებით გამორთულია. მიმდინარეობს ტექნიკური სამუშაოები. ვებ-გვერდი მალე ჩაირთვება' }];
        default:
            return [{ name: 'unknown', message: 'something went wrong' }];
    }
};
exports.getErrorMessages = getErrorMessages;
exports.errorObject = {
    data: null, status: apiGlobalTypes_1.ResponseStatuses.UNEXPECTED,
    errors: [{ name: 'parsers', message: 'მოხდა გაუთვალისწინებელი შემთხვევა' }]
};
// export const parseExceptions = (name: string, err: string): ExceptionErrors => {
//   captureException({ name, err })
//   return { data: null, status: ResponseStatuses.UNEXPECTED, errors:
//   [{ name: 'parsers', message: 'მოხდა გაუთვალისწინებელი შემთხვევა' }] }
// }
const throwException = (err) => {
    console.error(err);
    (0, react_1.captureException)(err);
};
exports.throwException = throwException;
