"use strict";
// export const fieldRequired = (value: number): string | undefined => value || value === 0 ? undefined : 'სავალდებულო ველი'
//
// export const fieldUnsignedNumber = (value: number): string | undefined => value > 0 ? undefined : 'სავალდებულოა დადებითი რიცხვი'
//
// export const requiredNumber = (value: string | undefined): string | undefined => !isNaN(parseInt(value)) ? undefined : 'სავალდებულოა მთელი რიცხვი'
//
// export const requiredFloat = (value: any): string | undefined => !isNaN(parseFloat(value)) ? undefined : 'სავალდებულოა რიცხვი ათწილადის სახით'
//
// export const nullOrMinSixSymbol = (value: any): string | undefined => value && !/.{6,}/i.test(value) ? 'მინიმუმ 6 სიმბოლო' : undefined
//
// export const minSixSymbol = (value: any): string | undefined => !value || !/.{6,}/i.test(value) ? 'მინიმუმ 6 სიმბოლო' : undefined
//
// export const validateEmail = (value: string): string | undefined => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
// 	? 'ელფოსტის ფორმატი არასწორია' : undefined
//
// export const validateDate = (value: string): string | undefined => value && !/^(0[1-9]|[12]\d|3[01])-(0\d|1[0-2])-(19\d{2}|20\d{2})$/i.test(value)
// 	? 'დროის ფორმატი არასწორია' : undefined
//
// export const requiredDate = (value: string): string | undefined => !value ? 'სავალდებულო ველი' : !/^(19\d{2}|20\d{2})-(0\d|1[0-2])-(0[1-9]|[12]\d|3[01])$/i.test(value)
// 	? 'დროის ფორმატი არასწორია' : undefined
Object.defineProperty(exports, "__esModule", { value: true });
exports.requiredEmail = void 0;
const requiredEmail = (value, translate) => !value ? translate('required_field', 'სავალდებულო ველი') : !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? translate('email_format_knowledge', 'ელფოსტის ფორმატი არასწორია') : undefined;
exports.requiredEmail = requiredEmail;
// export const confirmPassword = (value: any, allValue: any): string | undefined => allValue.attributes && allValue.attributes.password === value
// 	? undefined : 'სიმბოლოები არ დაემთხვა პაროლს'
//
// export const personalIdRequired = (value: any, allValue: any) => value || (allValue.attributes && allValue.attributes.passportNumber)
// 	? undefined : 'სავალდებულოა თუ პასპორტის ნომერი წარმოდგენილი არ არის'
//
// export const passportNumberRequired = (value: any, allValue: any): string | undefined => value || (allValue.attributes && allValue.attributes.identityNumber)
// 	? undefined : 'სავალდებულოა თუ პირადი ნომერი წარმოდგენილი არ არის'
//
// export const idCardValidToRequired = (value: string, allValue: any): string | undefined => !value && allValue.attributes &&
// (allValue.attributes.passportNumber || allValue.attributes.identityNumber)
// 	? 'სავალდებულოა თუ პასპორტის ნომერი ან პირადი ნომერი არის წარმოდგენილი' : value && !/^(19\d{2}|20\d{2})-(0\d|1[0-2])-(0[1-9]|[12]\d|3[01])$/i.test(value)
// 		? 'დროის ფორმატი არასწორია' : undefined
//
// export const fineRequiredWithAccountNumber = (value: any, allValue: any): string | undefined => !value && allValue.fineAccount
// 	? 'სავალდებულოა თუ ჯარიმის ანგარიში არის წარმოდგენილი' : undefined
//
// export const AccountNumberRequiredWithFine = (value: any, allValue: any): string | undefined => !value && allValue.fine
// 	? 'სავალდებულოა თუ ჯარიმა არის წარმოდგენილი' : undefined
