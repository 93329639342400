"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transferFile = exports.getMyFiles = exports.getAdministerFiles = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../privateRequest"));
const parsers_1 = require("api/my-space/myFiles/parsers");
const getAdministerFiles = async (params) => {
    const response = await Api.get('media-files', params);
    return (0, parsers_1.parseMyFiles)(response);
};
exports.getAdministerFiles = getAdministerFiles;
const getMyFiles = async (params) => {
    const response = await Api.get('user/media-files', params);
    return (0, parsers_1.parseMyFiles)(response);
};
exports.getMyFiles = getMyFiles;
const transferFile = async (uid) => {
    const response = await Api.post(`media-files/${uid}/transfer`, {});
    return (0, parsers_1.parseMyFile)(response);
};
exports.transferFile = transferFile;
