"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const NotificationsPage = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    return ((0, jsx_runtime_1.jsx)("div", { className: 'content d-flex flex-column flex-column-fluid', children: (0, jsx_runtime_1.jsx)("div", { className: 'd-flex flex-column-fluid', children: (0, jsx_runtime_1.jsx)("div", { className: 'container', children: (0, jsx_runtime_1.jsxs)("div", { className: 'card card-custom', children: [(0, jsx_runtime_1.jsx)("div", { className: 'card-header', children: (0, jsx_runtime_1.jsx)("div", { className: 'card-title', children: (0, jsx_runtime_1.jsx)("h3", { className: 'card-label', children: translate('field_notifications', 'შეტყობინებები') }) }) }), (0, jsx_runtime_1.jsxs)("div", { className: 'card-body', children: [(0, jsx_runtime_1.jsxs)("ul", { className: 'nav nav-tabs', children: [(0, jsx_runtime_1.jsx)("li", { className: 'nav-item', children: (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: '/user/notifications/list', className: `nav-link${props.tab === 'list' ? ' active' : ''}`, children: translate('all_notification', 'ყველა შეტყობინება') }) }), (0, jsx_runtime_1.jsx)("li", { className: 'nav-item', children: (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: '/user/notifications/params', className: `nav-link${props.tab === 'params' ? ' active' : ''}`, children: translate('notification_parameters', 'შეტობინების პარამატრები') }) })] }), (0, jsx_runtime_1.jsxs)("div", { className: 'tab-content mt-5', children: [(0, jsx_runtime_1.jsx)("div", { className: `tab-pane fade${props.tab === 'list' ? ' active show' : ''}`, children: props.tab === 'list' ? props.children : null }), (0, jsx_runtime_1.jsx)("div", { className: `tab-pane fade${props.tab === 'params' ? ' active show' : ''}`, children: props.tab === 'params' ? props.children : null })] })] })] }) }) }) }));
};
exports.default = NotificationsPage;
