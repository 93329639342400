"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProcurementItemsReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialProcurementItemsStore = {
    data: [],
    meta: store_1.initialMeta,
    errors: null,
    isFetching: true
};
const useProcurementItemsReducer = () => {
    return (0, react_1.useReducer)(ProcurementItemsReducer, initialProcurementItemsStore);
};
exports.useProcurementItemsReducer = useProcurementItemsReducer;
const ProcurementItemsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_PROCUREMENT_ITEMS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_PROCUREMENT_ITEMS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                meta: action.payload.meta || store_1.initialMeta,
                errors: action.payload.errors || null,
                isFetching: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
