"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const ReadOnlyBudgetEmployee = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    return ((0, jsx_runtime_1.jsx)("div", { className: 'card-body', children: (0, jsx_runtime_1.jsx)("div", { className: 'row', children: (0, jsx_runtime_1.jsx)("div", { className: 'col-md-10 mx-auto', children: (0, jsx_runtime_1.jsxs)("table", { className: 'table table-hover', children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { style: { maxWidth: 40 }, children: "#" }), (0, jsx_runtime_1.jsx)("th", { children: translate('field_address', 'მისამართი') }), (0, jsx_runtime_1.jsx)("th", { children: translate('address_category', 'მისამართის კატეგორია') })] }) }), (0, jsx_runtime_1.jsx)("tbody", { children: props.data.addresses?.map((el, index) => {
                                return ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("td", { children: index + 1 }), (0, jsx_runtime_1.jsx)("td", { children: el.address }), (0, jsx_runtime_1.jsx)("td", { children: el.type?.label })] }, index));
                            }) })] }) }) }) }));
};
exports.default = ReadOnlyBudgetEmployee;
