"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdministrativeOrderArticleFormValidation = exports.AdministrativeOrderFormValidation = void 0;
const validates_1 = require("core/helpers/validates");
const AdministrativeOrderFormValidation = (values, translate) => {
    const formErrors = {
        number: (0, validates_1.required)(values.number, translate),
        name: (0, validates_1.required)(values.name, translate),
        publicationDate: (0, validates_1.required)(values.publicationDate, translate),
        filesError: (0, validates_1.requiredNotEmptyArray)(values.files, translate)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.AdministrativeOrderFormValidation = AdministrativeOrderFormValidation;
const AdministrativeOrderArticleFormValidation = (values, translate) => {
    const formErrors = {
        name: (0, validates_1.required)(values.name, translate),
        number: (0, validates_1.required)(values.number, translate)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.AdministrativeOrderArticleFormValidation = AdministrativeOrderArticleFormValidation;
