"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountryFormValidation = void 0;
const validates_1 = require("core/helpers/validates");
const CountryFormValidation = (values, translate) => {
    const formErrors = {
        nameEn: (0, validates_1.required)(values.nameEn, translate)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.CountryFormValidation = CountryFormValidation;
