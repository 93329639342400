"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignSubmissionFormValidation = void 0;
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const validates_1 = require("core/helpers/validates");
const assignSubmissionFormValidation = (values, translate) => {
    const formErrors = {
        addresseesErrors: values.addressees.map(el => {
            return {
                addresseeStructuralUnit: (0, validates_1.required)(el.addresseeStructuralUnit, translate),
                addressee: (0, validates_1.required)(el.id, translate)
            };
        }),
        edocNumber: values.status === types_1.HRAssignSubmissionStatuses.SENT ? (0, validates_1.required)(values.edocNumber, translate) : undefined,
        requestRegistrationDate: values.status === types_1.HRAssignSubmissionStatuses.SENT ? (0, validates_1.required)(values.requestRegistrationDate, translate) : undefined,
        contactPerson: (0, validates_1.required)(values.contactPerson, translate),
        contactPersonEmail: (0, validates_1.required)(values.contactPersonEmail, translate),
        contactPersonPhoneNumber: (0, validates_1.required)(values.phoneNumber, translate),
        submissionAuthor: (0, validates_1.required)(values.submissionAuthor, translate),
        authorStructuralUnit: (0, validates_1.required)(values.authorStructuralUnit, translate),
        purpose: (0, validates_1.sentenceContainsTwoWordsAndLetter)(values.purpose, translate),
        filesError: values.status === types_1.HRAssignSubmissionStatuses.SENT ? (0, validates_1.requiredNotEmptyArray)(values.files, translate) : undefined
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value && key !== 'addresseesErrors')
            errors[key] = value;
        if (formErrors.addresseesErrors.some(err => err.addressee || err.addresseeStructuralUnit)) {
            errors[key] = value;
        }
    });
    return errors;
};
exports.assignSubmissionFormValidation = assignSubmissionFormValidation;
