"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConditionalSubmissionItemsReducer = exports.initialConditionalSubmissionItemsStore = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
exports.initialConditionalSubmissionItemsStore = {
    data: [],
    isInitial: true,
    errors: null,
    meta: store_1.initialMeta,
    isFetching: false,
    isUpdatingOrCreating: false,
    isDeleting: false,
    status: null,
    isUpdatingItemsList: false,
    isCreatingSubmission: false
};
const useConditionalSubmissionItemsReducer = () => {
    return (0, react_1.useReducer)(conditionalSubmissionItemsReducer, exports.initialConditionalSubmissionItemsStore);
};
exports.useConditionalSubmissionItemsReducer = useConditionalSubmissionItemsReducer;
const conditionalSubmissionItemsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CONDITIONAL_SUBMISSION_ITEMS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_CONDITIONAL_SUBMISSION_ITEMS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                isInitial: action.payload.data?.every(el => el.id === null) || false,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                status: action.payload.status,
                isFetching: false
            };
        case 'SEND_CREATE_SUBMISSION_REQUEST':
            return {
                ...state,
                isCreatingSubmission: true
            };
        case 'DONE_CREATE_SUBMISSION_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                isCreatingSubmission: false
            };
        case 'SEND_CONDITIONAL_SUBMISSION_ITEM_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_CONDITIONAL_SUBMISSION_ITEM_DELETE_REQUEST':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        total: state.meta.pagination.total - action.payload.length,
                        count: state.meta.pagination.count - action.payload.length
                    }
                },
                isDeleting: false
            };
        case 'SEND_ITEMS_LIST_UPDATE_REQUEST':
            return {
                ...state,
                errors: null,
                isUpdatingItemsList: true
            };
        case 'DONE_ITEMS_LIST_UPDATE_REQUEST':
            return {
                ...state,
                data: action.payload.conditionalSubmissionItems.data || state.data,
                isInitial: false,
                errors: action.payload.conditionalSubmissionItems.errors,
                isUpdatingItemsList: false,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        total: state.meta.pagination.total + action.payload.newItemsLength,
                        count: state.meta.pagination.count + action.payload.newItemsLength
                    }
                }
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
