"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.procurementReducer = exports.useProcurementReducer = exports.initialProcurementNavigate = exports.initialProcurementTemplateValue = exports.initialProcurement = void 0;
const react_1 = require("react");
const types_1 = require("api/documents/purchases/procurements/types");
const helper_1 = require("../../../../common/helper");
exports.initialProcurement = {
    moduleType: 'Procurement',
    number: '',
    signedDocumentNumber: '',
    disableCorrectionCreate: false,
    dateOfConclusion: null,
    deliveryDate: (0, helper_1.getToday)(),
    contractValidityDate: (0, helper_1.getToday)(),
    advanceGuaranteeValidityPeriod: null,
    contractGuaranteeValidityPeriod: null,
    goodsGuaranteeValidityPeriod: null,
    templateType: null,
    declineMessage: '',
    actuallyDoneComment: null,
    tenderLink: '',
    remainingItemsQuantity: 0,
    revertComment: null,
    cmr: '',
    spa: '',
    con: '',
    status: types_1.ProcurementStatuses.DRAFT,
    subStatus: types_1.ProcurementSubStatuses.NOT_EXECUTED,
    sumPrice: NaN,
    creator: null,
    type: null,
    company: null,
    companyBankAccount: null,
    companyAddress: null,
    companySignatory: null,
    files: [],
    attachedFiles: [],
    attachedSignedFiles: [],
    approvers: [],
    procurementFile: null,
    lock: false,
    procurementVisualStatus: { name: 'პროექტი', color: 'secondary' },
    subVisualStatus: { name: 'პროექტი', color: 'secondary' },
    isOther: false,
    original: true,
    legalBasis: null,
    textFieldForLegalBasis: '',
    hasForceMajeureComment: false,
    guaranteeArticles: [],
    guaranteeArticle: null
};
exports.initialProcurementTemplateValue = { content: '' };
const initialProcurementState = {
    data: exports.initialProcurement,
    templateData: exports.initialProcurementTemplateValue,
    status: null,
    isUpdatingTreasuryBudgetingArticle: false,
    isUpdatingTreasuryProject: false,
    errors: null,
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    isCreating: false,
    isSuspending: false,
    isLoading: false,
    isDeclining: false,
    isApproving: false,
    isVerifying: false,
    isLocking: false,
    isLockingOut: false,
    isReverting: false,
    isAttachingSignature: false,
    isSendingSignedDocument: false,
    isSendingDocument: false,
    isActuallyDone: false,
    isTemplateUpdating: false,
    isTemplateFetching: false,
    isTemplateFetched: false,
    templateErrors: null,
    isGenerating: false
};
exports.initialProcurementNavigate = {
    next: false,
    previous: false,
    isLoading: false,
    lastElement: false,
    firstElement: false
};
const useProcurementReducer = () => {
    return (0, react_1.useReducer)(exports.procurementReducer, initialProcurementState);
};
exports.useProcurementReducer = useProcurementReducer;
const procurementReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_TEMPLATE_REQUEST':
            return {
                ...state,
                errors: null,
                isTemplateFetching: true,
                isLoading: true
            };
        case 'DONE_TEMPLATE_REQUEST':
            return {
                ...state,
                templateData: action.payload.data || state.templateData,
                errors: action.payload.errors
                    ? state.errors
                        ? state.errors.concat(action.payload.errors) : action.payload.errors
                    : state.errors,
                isTemplateFetched: true,
                status: action.payload.status,
                isTemplateFetching: false,
                isLoading: state.isCreating
                    || state.isSuspending
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
                    || state.isReverting
                    || state.isUpdating
            };
        case 'SEND_TEMPLATE_UPDATE':
            return {
                ...state,
                errors: null,
                isTemplateUpdating: true
            };
        case 'DONE_TEMPLATE_UPDATE':
            return {
                ...state,
                templateData: action.payload.data || state.templateData,
                templateErrors: action.payload.errors,
                isTemplateUpdating: false,
                status: action.payload.status
            };
        case 'SEND_TEMPLATE_GENERATE':
            return {
                ...state,
                errors: null,
                isGenerating: true
            };
        case 'DONE_TEMPLATE_GENERATE':
            return {
                ...state,
                templateData: action.payload.data || state.templateData,
                errors: action.payload.errors,
                isGenerating: false
            };
        case 'SEND_PROCUREMENT_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isFetched: true,
                isLoading: state.isUpdating
                    || state.isCreating
                    || state.isSuspending
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
                    || state.isReverting
            };
        case 'SEND_PROCUREMENT_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                templateData: {
                    content: action.payload.data?.content ? action.payload.data.content : state.templateData.content
                },
                status: action.payload.status,
                errors: action.payload.errors,
                isUpdating: false,
                isLoading: state.isFetching
                    || state.isCreating
                    || state.isSuspending
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
                    || state.isReverting
            };
        case 'SEND_PROCUREMENT_CREATING':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_CREATING':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isCreating: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isSuspending
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
                    || state.isReverting
            };
        case 'SEND_PROCUREMENT_DECLINE':
            return {
                ...state,
                isDeclining: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_DECLINE':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isDeclining: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isSuspending
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
                    || state.isReverting
            };
        case 'SEND_PROCUREMENT_SUSPEND':
            return {
                ...state,
                isSuspending: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_SUSPEND':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isSuspending: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
                    || state.isReverting
            };
        case 'SEND_PROCUREMENT_ACTUALLY_DONE':
            return {
                ...state,
                isActuallyDone: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_ACTUALLY_DONE':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isActuallyDone: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
                    || state.isReverting
            };
        case 'SEND_PROCUREMENT_APPROVE':
            return {
                ...state,
                isApproving: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_APPROVE':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isApproving: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isSuspending
                    || state.isDeclining
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
                    || state.isReverting
            };
        case 'SEND_PROCUREMENT_VERIFY':
            return {
                ...state,
                isVerifying: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_VERIFY':
            return {
                ...state,
                isVerifying: false,
                errors: action.payload.errors,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isSuspending
                    || state.isDeclining
                    || state.isApproving
                    || state.isLocking
                    || state.isLockingOut
                    || state.isReverting
            };
        case 'SEND_PROCUREMENT_LOCK':
            return {
                ...state,
                isLocking: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_LOCK':
            return {
                ...state,
                isLocking: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isSuspending
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLockingOut
                    || state.isReverting
            };
        case 'SEND_PROCUREMENT_LOCKOUT':
            return {
                ...state,
                isLockingOut: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_LOCKOUT':
            return {
                ...state,
                isLockingOut: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isSuspending
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isReverting
            };
        case 'SEND_UPDATE_TREASURY_BUDGET_ARTICLE_REQUEST':
            return {
                ...state,
                isUpdatingTreasuryBudgetingArticle: true
            };
        case 'DONE_UPDATE_TREASURY_BUDGET_ARTICLE_REQUEST':
            return {
                ...state,
                isUpdatingTreasuryBudgetingArticle: false
            };
        case 'SEND_UPDATE_TREASURY_PROJECT_REQUEST':
            return {
                ...state,
                isUpdatingTreasuryProject: true
            };
        case 'DONE_UPDATE_TREASURY_PROJECT_REQUEST':
            return {
                ...state,
                isUpdatingTreasuryProject: false
            };
        case 'SEND_PROCUREMENT_REVERT':
            return {
                ...state,
                isReverting: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_REVERT':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isReverting: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isSuspending
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
            };
        case 'SEND_ATTACH_SIGNATURE':
            return {
                ...state,
                isAttachingSignature: true,
                isLoading: true
            };
        case 'DONE_ATTACH_SIGNATURE':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isReverting: false,
                isAttachingSignature: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isSuspending
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
            };
        case 'SEND_SIGNATURE_DOCUMENT':
            return {
                ...state,
                isSendingDocument: true,
                isLoading: true
            };
        case 'DONE_SIGNATURE_DOCUMENT':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isReverting: false,
                isSendingDocument: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isSuspending
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
            };
        case 'SEND_ATTACH_SIGNATURE_DOCUMENT':
            return {
                ...state,
                isCreating: true,
                isSendingSignedDocument: true,
                isLoading: true
            };
        case 'DONE_ATTACH_SIGNATURE_DOCUMENT':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isReverting: false,
                isCreating: false,
                isUpdating: false,
                isSendingSignedDocument: false,
                isLoading: state.isFetching
                    || state.isSuspending
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
            };
        case 'UPDATE_PROCUREMENT_FILE_DATA':
            return {
                ...state,
                data: {
                    ...state.data,
                    procurementFile: state.data.procurementFile && {
                        ...state.data.procurementFile,
                        link: action.payload.link
                    }
                }
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.procurementReducer = procurementReducer;
