"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFakeInvoice = void 0;
const types_1 = require("api/documents/purchases/invoices/types");
const index_1 = require("api/index");
const helper_1 = require("../../../common/helper");
const helper_2 = require("../../../documents/purchases/invoices/helper");
const useAccountChange_1 = require("src/modules/debuging/hooks/useAccountChange");
const useFakeProcurement_1 = require("./useFakeProcurement");
const react_1 = require("react");
const context_1 = require("src/context/context");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const useFakeInvoice = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    const procurementHook = (0, useFakeProcurement_1.useFakeProcurement)({ auth: props.auth, uid: props.uid });
    const [isCreating, setIsCreating] = (0, react_1.useState)(false);
    const [isChangingStatus, setIsChangingStatus] = (0, react_1.useState)(false);
    const headOfPurchasesStructuralUnitDev = 'შესყიდვების დეპარტამენტის უფროსი';
    const headOfPurchasesStructuralUnitStaging = 'შესყიდვების სამსახურის უფროსი';
    const purchasesDepartmentAccount = props.auth.state.data.user?.accounts.find(el => el.name === 'შესყიდვების დეპარტამენტის უფროსი' || el.name === 'შესყიდვების სამსახურის უფროსი');
    const useAccountChangeHook = (0, useAccountChange_1.useAccountChange)({ authUser: props.auth.state.data.user });
    const { toast } = (0, context_1.useToastContext)();
    const createInvoice = async (loading = true) => {
        if (loading)
            setIsCreating(true);
        const procurement = await procurementHook.sendFakeDocument(false);
        if (procurement === null) {
            if (loading)
                setIsCreating(false);
            return;
        }
        if (procurement) {
            const procurementId = procurement?.id;
            if (procurementId) {
                const data = {
                    companyId: procurement?.company.id,
                    creationDate: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
                    deliveryPlace: '',
                    fine: null,
                    fineAccount: null,
                    receiptDate: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
                    template: null,
                    type: types_1.InvoiceType.ONLY_FILE,
                    warehouseId: '1'
                };
                const file = { fileUids: [{ type: types_1.InvoiceType.ONLY_FILE, uid: props.uid }] };
                const items = await index_1.InvoicesApi.getPurchasedItems({ filters: { procurementId: procurementId } });
                if (items.data) {
                    const params = { filters: { procurementItemIds: items.data.map(el => el.procurementItemId) } };
                    const userSignatories = await index_1.InvoicesApi.getUserSignatories(params);
                    const signatories = {
                        signatories: {
                            users: userSignatories.data.map(el => {
                                return {
                                    type: el.type || types_1.InvoiceSignatoryType.PERFORMER,
                                    userId: el.userId,
                                    structuralUnitId: el.structuralUnitId
                                };
                            }) || [],
                            companies: [
                                {
                                    companySignatoryId: procurement.companySignatory?.id || null,
                                    companySignatoryPhoneId: null
                                }
                            ]
                        }
                    };
                    const fullData = { ...data, ...file, procurementId, signatories: signatories.signatories };
                    const invoice = await index_1.InvoicesApi.createInvoice(fullData);
                    if (invoice) {
                        const transformedItems = (0, helper_2.transformInvoiceItemsListParams)(items.data);
                        await index_1.InvoicesApi.updateInvoiceItem(invoice.data?.id, transformedItems);
                        toast.success(translate('invoice_successfully_created', 'ინვოისი წარმატებით შეიქმნა'));
                        if (loading)
                            setIsCreating(false);
                        return invoice.data?.id;
                    }
                }
                else {
                    toast.danger(translate('invoice_failed_create', 'ინვოისი ვერ შეიქმნა'));
                    return null;
                }
            }
        }
        return null;
    };
    const changeStatus = async (loading = true) => {
        if (loading)
            setIsChangingStatus(true);
        const invoiceId = await createInvoice(false);
        if (invoiceId === null) {
            if (loading)
                setIsChangingStatus(false);
            return;
        }
        if (invoiceId) {
            await index_1.InvoicesApi.changeStatus(invoiceId);
            const emulatedUser = await useAccountChangeHook.changeUserOrAccount(headOfPurchasesStructuralUnitDev, headOfPurchasesStructuralUnitStaging, purchasesDepartmentAccount);
            await index_1.InvoicesApi.changeStatus(invoiceId);
            if (emulatedUser?.id)
                await useAccountChangeHook.logoutAsEmulateUserForDebug(props.auth.state.data.user.id);
            await index_1.InvoicesApi.notifyDepartments(invoiceId, { sentToAccounting: true, sentToWarehouse: true });
            const response = await index_1.InvoicesApi.changeStatus(invoiceId);
            if (loading)
                setIsChangingStatus(false);
            if (response.data !== null) {
                const subStatus = await index_1.InvoicesApi.changeSubStatus(invoiceId);
                if (subStatus) {
                    toast.success(translate('invoice_successfully_switched_signed_status', 'ინვოისის ხელმოწერილ სტატუსში წარმატებით გადავიდა'));
                }
                else {
                    toast.danger(translate('invoice_status_change_failed', 'ინვოისის სტატუსი ვერ შეიცვალა'));
                }
            }
            else {
                toast.danger(translate('invoice_status_change_failed', 'ინვოისის სტატუსი ვერ შეიცვალა'));
            }
        }
        else {
            toast.danger(translate('something_went_wrong', 'მოხდა გაუთვალისწინებელი შეცდომა'));
        }
    };
    return {
        createInvoice,
        changeStatus,
        isCreating,
        isChangingStatus
    };
};
exports.useFakeInvoice = useFakeInvoice;
