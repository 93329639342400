"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VacationFormValidation = void 0;
const validates_1 = require("core/helpers/validates");
const helper_1 = require("src/modules/common/helper");
const VacationFormValidation = (values, translate) => {
    const formErrors = {
        user: (0, validates_1.required)(values.user, translate),
        substituteUser: (0, validates_1.required)(values.substituteUser, translate),
        comment: (0, validates_1.required)(values.comment, translate),
        startDate: (0, validates_1.required)(values.startDate, translate),
        endDate: (0, validates_1.required)(values.endDate, translate) || validateEndDate(values.startDate, translate, values.endDate),
        structuralUnit: (0, validates_1.required)(values.structuralUnit, translate)
            || checkDateValidity(values.startDate, translate, values.endDate, values.structuralUnit.deletedAt),
        substituteStructuralUnit: (0, validates_1.required)(values.substituteStructuralUnit, translate)
            || checkDateValidity(values.startDate, translate, values.endDate, (values.substituteStructuralUnit.deletedAt))
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.VacationFormValidation = VacationFormValidation;
const checkDateValidity = (startDate, translate, endDate, deletedAt) => {
    if (!startDate || !endDate)
        return;
    if (deletedAt
        && (new Date((0, helper_1.transformParamsDate)(startDate)) > new Date((0, helper_1.transformParamsDate)(deletedAt))
            || new Date((0, helper_1.transformParamsDate)(endDate)) > new Date((0, helper_1.transformParamsDate)(deletedAt)))) {
        return translate('not_exceed_release_date', 'მითითებული თანამდებობიდან თანამშრომელი გათავისუფლებულია. გთხოვთ, დარწმუნდეთ, რომ დაწყების და დასრულების თარიღები არ აღემატება გათავისუფლების თარიღს');
    }
    else {
        return;
    }
};
const validateEndDate = (startDate, translate, endDate) => {
    if (!startDate || !endDate)
        return;
    if (new Date((0, helper_1.transformParamsDate)(startDate)) > new Date((0, helper_1.transformParamsDate)(endDate)))
        return translate('start_should_not_exceed_finish', 'დაწყება არ უნდა აღემატებოდეს დასრულებას');
    return;
};
