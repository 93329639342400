"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const types_1 = require("api/documents/purchases/procurements/types");
const constants_1 = require("../helpers/constants");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const ReadOnlyProcurementContract = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    return ((0, jsx_runtime_1.jsx)("div", { className: 'card-body p-3', children: (0, jsx_runtime_1.jsxs)("div", { className: 'submission-item-row', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'ml-3', children: [(0, jsx_runtime_1.jsxs)("strong", { children: [translate('contract_type', 'კონტრაქტის ტიპი'), ":\u00A0"] }), props.procurementType?.label] }), (0, jsx_runtime_1.jsxs)("div", { className: 'ml-3', children: [(0, jsx_runtime_1.jsxs)("strong", { children: [translate('field_templateType', 'შაბლონის ტიპი'), ":\u00A0"] }), props.templateType === types_1.TemplateTypes.ELECTRONIC
                            ? constants_1.procurementType[props.templateType]
                            : props.templateType === types_1.TemplateTypes.CONSOLIDATED
                                ? translate('consolidated', 'კონსოლიდირებული')
                                : translate('simplified', 'გამარტივებული')] })] }) }));
};
exports.default = ReadOnlyProcurementContract;
