"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const MyStructureSingleBranch = (props) => {
    const { structuralUnit, openParent, closeParent, fetchingHierarchy, handleOpenHierarchy, isLoading } = props;
    const { isPermanent, iconType, fullName, employee, type, parentIds } = structuralUnit;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("i", { className: 'jstree-icon jstree-ocl', onClick: () => structuralUnit.isOpen ? closeParent(structuralUnit) : openParent(structuralUnit) }), (0, jsx_runtime_1.jsxs)("a", { href: '#', className: `jstree-anchor h-auto${structuralUnit.isUpdated ? ' text-warning' : ''}${isLoading ? ' link-disabled' : ''}`, onClick: e => e.preventDefault(), style: { userSelect: 'inherit', width: '98%' }, children: [(0, jsx_runtime_1.jsx)("i", { className: `jstree-icon jstree-themeicon jstree-themeicon-custom${isPermanent ? ' text-muted' : ''} fas${iconType}` }), (0, jsx_runtime_1.jsxs)("span", { style: { whiteSpace: 'break-spaces' }, children: [fullName, " ", employee ? (0, jsx_runtime_1.jsxs)("b", { children: [" - ", employee, " "] }) : null] }), type === 1 && parentIds?.length > 1
                        ? ((0, jsx_runtime_1.jsx)("i", { className: `ml-2 fas fa-sitemap ${structuralUnit.identifier === fetchingHierarchy ? 'fa-spin' : ''}`, onClick: () => handleOpenHierarchy(structuralUnit) })) : null] })] }));
};
exports.default = MyStructureSingleBranch;
