"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectCreateStructuralUnitReducer = exports.useProjectCreateStructuralUnitReducer = exports.initialProjectStructuralUnitState = void 0;
const react_1 = require("react");
exports.initialProjectStructuralUnitState = {
    data: {
        attachedStructuralUnits: [],
        suggestedStructuralUnit: null,
        previousSuggested: null,
        previousAttachedStructuralUnits: [],
        loadingIds: []
    },
    status: null,
    errors: null,
    isFetching: false,
    isAttaching: false,
    isFetchingSuggestions: false
};
const useProjectCreateStructuralUnitReducer = () => {
    return (0, react_1.useReducer)(exports.ProjectCreateStructuralUnitReducer, exports.initialProjectStructuralUnitState);
};
exports.useProjectCreateStructuralUnitReducer = useProjectCreateStructuralUnitReducer;
const ProjectCreateStructuralUnitReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_GET_STRUCTURAL_UNIT':
            return {
                ...state,
                errors: null,
                isFetching: true
            };
        case 'DONE_GET_STRUCTURAL_UNIT':
            return {
                ...state,
                data: {
                    ...state.data,
                    attachedStructuralUnits: action.payload.data?.attachedStructuralUnits || exports.initialProjectStructuralUnitState.data.attachedStructuralUnits
                },
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false
            };
        case 'SEND_GET_SUGGESTED_STRUCTURAL_UNIT':
            return {
                ...state,
                errors: null,
                isFetchingSuggestions: true
            };
        case 'DONE_GET_SUGGESTED_STRUCTURAL_UNIT':
            return {
                ...state,
                data: {
                    ...state.data,
                    suggestedStructuralUnit: action.payload.data?.structuralUnit && !state.data.attachedStructuralUnits.find(el => el.id === action.payload.data?.structuralUnit?.id)
                        ? action.payload.data?.structuralUnit
                        : exports.initialProjectStructuralUnitState.data.suggestedStructuralUnit
                },
                errors: action.payload.errors,
                status: action.payload.status,
                isFetchingSuggestions: false
            };
        case 'SEND_ATTACH_STRUCTURAL_UNIT':
            return {
                ...state,
                data: {
                    ...state.data,
                    attachedStructuralUnits: state.data.attachedStructuralUnits.concat(action.payload.unit),
                    suggestedStructuralUnit: action.payload.unit.id === state.data.suggestedStructuralUnit?.id ? null : state.data.suggestedStructuralUnit,
                    loadingIds: state.data.loadingIds.concat(action.payload.structuralUnitId),
                    previousSuggested: state.data.suggestedStructuralUnit,
                    previousAttachedStructuralUnits: state.data.attachedStructuralUnits
                },
                errors: null,
                isAttaching: true
            };
        case 'DONE_ATTACH_STRUCTURAL_UNIT': {
            return {
                ...state,
                data: {
                    ...state.data,
                    attachedStructuralUnits: action.payload.res.errors
                        ? state.data.previousAttachedStructuralUnits
                        : state.data.attachedStructuralUnits,
                    suggestedStructuralUnit: action.payload.res.errors ? state.data.previousSuggested : state.data.suggestedStructuralUnit,
                    loadingIds: state.data.loadingIds.filter(id => action.payload.structuralUnitId !== id)
                },
                errors: null,
                status: action.payload.res.status,
                isAttaching: false
            };
        }
        case 'DUPLICATE_ALERT': {
            return {
                ...state,
                data: {
                    ...state.data,
                    attachedStructuralUnits: state.data.attachedStructuralUnits.map(el => {
                        if (el.id === action.payload) {
                            return {
                                ...el,
                                alert: 'dragged'
                            };
                        }
                        else {
                            return el;
                        }
                    })
                },
                errors: state.errors,
                status: state.status
            };
        }
        case 'CLEAR_ALERT': {
            return {
                ...state,
                data: {
                    ...state.data,
                    attachedStructuralUnits: state.data.attachedStructuralUnits.map(el => ({
                        ...el,
                        alert: undefined
                    }))
                },
                errors: state.errors,
                status: state.status
            };
        }
        default:
            return state;
    }
};
exports.ProjectCreateStructuralUnitReducer = ProjectCreateStructuralUnitReducer;
