"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyReducer = exports.useCompanyReducer = void 0;
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const initialCompanyState = {
    data: {
        attributes: {
            identificationNumber: '',
            name: '',
            legalStatus: null,
            legalStatusShortName: '',
            status: apiGlobalTypes_1.SystemStatus.ACTIVE,
            foreignCompany: apiGlobalTypes_1.YesNoStatus.NO,
            isResident: apiGlobalTypes_1.YesNoStatus.YES,
            hasActiveResidencyFile: false
        },
        signatories: [],
        addresses: [],
        bankAccounts: [],
        residencyFiles: []
    },
    loadingItems: [],
    deletingItems: [],
    errors: null,
    isCreating: false,
    addingSignatory: false,
    addingAddress: false,
    addingBankAccount: false,
    addingCompanyResidencyFile: false,
    isFetching: false,
    isUpdating: false,
    deletingSignatory: false,
    isLoading: false
};
const useCompanyReducer = () => {
    return (0, react_1.useReducer)(exports.CompanyReducer, initialCompanyState);
};
exports.useCompanyReducer = useCompanyReducer;
const CompanyReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_COMPANY_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_COMPANY_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    id: action.payload.data?.id,
                    attributes: action.payload.data?.attributes || state.data.attributes,
                    signatories: action.payload.data?.signatories || state.data.signatories,
                    bankAccounts: action.payload.data?.bankAccounts || state.data.bankAccounts,
                    addresses: action.payload.data?.addresses || state.data.addresses,
                    residencyFiles: action.payload.data?.residencyFiles || state.data.residencyFiles
                },
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_COMPANY_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_COMPANY_UPDATE':
            return {
                ...state,
                data: {
                    ...state.data,
                    attributes: action.payload.data?.attributes || state.data.attributes
                },
                errors: action.payload.errors,
                isUpdating: false,
                isLoading: state.isCreating || state.isFetching
            };
        case 'SEND_CREATE_COMPANY':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_CREATE_COMPANY':
            return {
                ...state,
                data: {
                    ...state.data,
                    attributes: action.payload.data?.attributes || state.data.attributes
                },
                errors: action.payload.errors,
                isCreating: false,
                isLoading: state.isUpdating || state.isFetching
            };
        case 'SEND_ADD_COMPANY_SIGNATORY':
            return {
                ...state,
                addingSignatory: true
            };
        case 'DONE_ADD_COMPANY_SIGNATORY':
            return {
                ...state,
                data: {
                    ...state.data,
                    signatories: action.payload.data || state.data.signatories
                },
                errors: action.payload.errors,
                addingSignatory: false
            };
        case 'SEND_ADD_COMPANY_ADDRESS':
            return {
                ...state,
                addingAddress: true
            };
        case 'DONE_ADD_COMPANY_ADDRESS':
            return {
                ...state,
                data: {
                    ...state.data,
                    addresses: action.payload.data || state.data.addresses
                },
                errors: action.payload.errors,
                addingAddress: false
            };
        case 'SEND_ADD_COMPANY_RESIDENCY_FILE':
            return {
                ...state,
                addingCompanyResidencyFile: true
            };
        case 'DONE_ADD_COMPANY_RESIDENCY_FILE':
            return {
                ...state,
                data: {
                    ...state.data,
                    residencyFiles: action.payload.data?.items || state.data.residencyFiles,
                    attributes: {
                        ...state.data.attributes,
                        hasActiveResidencyFile: action.payload.data?.hasActiveResidencyFile || state.data.attributes.hasActiveResidencyFile
                    }
                },
                errors: action.payload.errors,
                addingCompanyResidencyFile: false
            };
        case 'SEND_DELETE_ADDRESS':
            return {
                ...state,
                deletingItems: [...state.deletingItems, action.payload]
            };
        case 'DONE_DELETE_ADDRESS':
            return {
                ...state,
                data: {
                    ...state.data,
                    addresses: action.payload.response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS
                        ? state.data.addresses.filter(address => address.id !== action.payload.id)
                        : state.data.addresses
                },
                errors: action.payload.response.errors,
                deletingItems: state.deletingItems.filter(el => el !== action.payload.id)
            };
        case 'SEND_ADD_COMPANY_BANK_ACCOUNT':
            return {
                ...state,
                addingBankAccount: true
            };
        case 'SEND_DELETE_SIGNATORY':
            return {
                ...state,
                deletingSignatory: true
            };
        case 'DONE_DELETE_SIGNATORY':
            return {
                ...state,
                deletingSignatory: false,
                errors: action.payload.errors
            };
        case 'SET_LOADING_ITEM':
            return {
                ...state,
                loadingItems: [...state.loadingItems, action.payload]
            };
        case 'UN_SET_LOADING_ITEM':
            return {
                ...state,
                loadingItems: state.loadingItems.filter(id => !(id === action.payload))
            };
        case 'DONE_ADD_COMPANY_BANK_ACCOUNT':
            return {
                ...state,
                data: {
                    ...state.data,
                    bankAccounts: action.payload.data || state.data.bankAccounts
                },
                errors: action.payload.errors,
                addingBankAccount: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.CompanyReducer = CompanyReducer;
