"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateProcurementCorrectionFromSubmissionValidation = exports.addCorrectionValidation = exports.createProcurementCorrectionValidation = void 0;
const types_1 = require("api/documents/purchases/procurementCorrections/types");
const validates_1 = require("core/helpers/validates");
const createProcurementCorrectionValidation = (values, translate) => {
    const letter = values.submissionOrLetterType === types_1.ProcurementCorrectionType.LETTER;
    const formErrors = {
        type: (0, validates_1.required)(values.correctionType, translate),
        reason: (0, validates_1.requiredNotEmptyArray)(values.reasons, translate),
        letterNumber: letter ? (0, validates_1.required)(values.letterNumber, translate) : undefined,
        letterDate: letter ? (0, validates_1.required)(values.letterDate, translate) : undefined,
        fileErrors: letter ? (0, validates_1.requiredNotEmptyArray)(values.letterOrSubmissionFiles, translate) : undefined
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.createProcurementCorrectionValidation = createProcurementCorrectionValidation;
const addCorrectionValidation = (values, translate) => {
    const formErrors = {
        employee: (0, validates_1.required)(values.submissionAuthor, translate),
        position: (0, validates_1.required)(values.submissionAuthorPosition, translate),
        submissionNumber: (0, validates_1.required)(values.submissionNumber, translate),
        submissionRequestRegistration: (0, validates_1.required)(values.submissionRequestRegistration, translate),
        submissionDeadline: (0, validates_1.required)(values.submissionDeadline, translate),
        submissionExecution: (0, validates_1.required)(values.submissionExecution, translate),
        fileErrors: (0, validates_1.requiredNotEmptyArray)(values.letterOrSubmissionFiles, translate)
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.addCorrectionValidation = addCorrectionValidation;
const CreateProcurementCorrectionFromSubmissionValidation = (values, translate) => {
    const submissionsWithItems = values.submissions.filter(el => el.submissionId);
    const atLeastOneCheckedItemInSubmissions = submissionsWithItems.map(el => {
        return el.submissionItems?.some(el => el.checked) || el.chosenItems?.some(el => el.checked);
    });
    const formErrors = {
        reasonErrors: (0, validates_1.requiredNotEmptyArray)(values.reasons, translate),
        submissionErrors: (0, validates_1.requiredNotEmptyArray)(values.submissions, translate) ? 'წარდგინება სავალდებულოა' : undefined,
        selectedSubmissionErrors: submissionsWithItems.length === 0
            ? undefined
            : atLeastOneCheckedItemInSubmissions.length > 0 && atLeastOneCheckedItemInSubmissions.every(el => el)
                ? undefined
                : 'გთხოვთ დარწმუნდეთ, რომ ყველა წარდგინების დოკუმენტში არჩეულია ერთი საგანი მაინც.'
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.CreateProcurementCorrectionFromSubmissionValidation = CreateProcurementCorrectionFromSubmissionValidation;
