"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const projectCreateStructuralUnits_1 = require("../stores/projectCreateStructuralUnits");
const index_1 = require("api/index");
const useProjectCreateStructuralUnits = (props) => {
    const [state, dispatch] = (0, projectCreateStructuralUnits_1.useProjectCreateStructuralUnitReducer)();
    const getStructuralUnits = async (projectId) => {
        dispatch({ type: 'SEND_GET_STRUCTURAL_UNIT' });
        const structuralUnits = await index_1.ProjectsApi.getStructuralUnitForProjectCreation(projectId);
        dispatch({ type: 'DONE_GET_STRUCTURAL_UNIT', payload: structuralUnits });
    };
    const getSuggestedStructuralUnits = async (budgetSourceId) => {
        dispatch({ type: 'SEND_GET_SUGGESTED_STRUCTURAL_UNIT' });
        const suggestion = await index_1.ProjectsApi.getStructuralUnitSuggestionForProjectCreation({ budgetSourceId });
        dispatch({ type: 'DONE_GET_SUGGESTED_STRUCTURAL_UNIT', payload: suggestion });
    };
    const attachStructuralUnit = async (unit) => {
        if (state.data.attachedStructuralUnits.some(el => el.id === unit.id)) {
            dispatch({ type: 'DUPLICATE_ALERT', payload: unit.id });
            return;
        }
        dispatch({ type: 'SEND_ATTACH_STRUCTURAL_UNIT', payload: { unit, structuralUnitId: unit.id } });
        const structuralUnit = await index_1.ProjectsApi.attachStructuralUnitForProjectCreation(props.projectId, { structuralUnitIds: [unit.id] });
        dispatch({ type: 'DONE_ATTACH_STRUCTURAL_UNIT', payload: { res: structuralUnit, structuralUnitId: unit.id } });
        return structuralUnit;
    };
    (0, react_1.useEffect)(() => {
        if (props.projectId && props.budgetSourceId)
            getStructuralUnits(props.projectId).then(() => getSuggestedStructuralUnits(props.budgetSourceId));
    }, [props.projectId, props.budgetSourceId]);
    return {
        state,
        attachStructuralUnit,
        dispatch
    };
};
exports.default = useProjectCreateStructuralUnits;
