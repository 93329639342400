"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const ToUrlButton = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    return ((0, jsx_runtime_1.jsxs)(react_router_dom_1.Link, { to: props.url, className: `btn m-1 ${props.disabled ? 'disabled' : ''} btn-${props.color || 'secondary'}`, style: { marginRight: 5 }, children: [" ", `${props.goesTo || translate('button_list', 'სიაზე')} ${translate('button_transition', 'გადასვლა')}`] }));
};
exports.default = ToUrlButton;
