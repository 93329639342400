"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.structuralUnitValidations = exports.contractFormValuesValidations = void 0;
const types_1 = require("api/references/structuralUnitsUpgraded/types");
const validates_1 = require("core/helpers/validates");
const contractFormValuesValidations = (values, translate) => {
    const formErrors = {
        structuralUnit: (0, validates_1.required)(values.structuralUnit, translate),
        user: (0, validates_1.required)(values.user, translate),
        startDate: (0, validates_1.required)(values.startDate, translate)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.contractFormValuesValidations = contractFormValuesValidations;
const structuralUnitValidations = (values, translate) => {
    const formErrors = {
        parents: values.type.id === types_1.StructuralUnitType.STRUCTURAL_UNIT ? (0, validates_1.requiredNotEmptyArray)(values.parents, translate) : undefined,
        parent: values.type.id === types_1.StructuralUnitType.POSITION ? (0, validates_1.required)(values.parent, translate) : undefined,
        type: (0, validates_1.required)(values.type, translate),
        useUnitPrefix: values.type.id === types_1.StructuralUnitType.POSITION ? (0, validates_1.required)(values.useUnitPrefix, translate) : undefined,
        name: (0, validates_1.required)(values.name, translate),
        positionType: values.type.id === types_1.StructuralUnitType.POSITION ? (0, validates_1.required)(values.positionType, translate) : undefined,
        positionStatus: values.type.id === types_1.StructuralUnitType.POSITION ? (0, validates_1.required)(values.positionStatus, translate) : undefined,
        basicFunctionDuties: values.type.id === types_1.StructuralUnitType.POSITION ? (0, validates_1.required)(values.basicFunctionDuties, translate) : undefined,
        quantity: values.quantity ? (0, validates_1.requiredPositiveNumber)(values.quantity, translate) : undefined
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.structuralUnitValidations = structuralUnitValidations;
