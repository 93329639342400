"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCorrectionCalculationsReducer = void 0;
const react_1 = require("react");
const initialCorrectionCalculation = {
    data: {
        budgetArticles: [],
        headerProcurements: []
    },
    errors: null,
    isFetching: true
};
const useCorrectionCalculationsReducer = () => {
    return (0, react_1.useReducer)(CorrectionCalculationsReducer, initialCorrectionCalculation);
};
exports.useCorrectionCalculationsReducer = useCorrectionCalculationsReducer;
const CorrectionCalculationsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CORRECTION_CALCULATE_REQUEST':
            return {
                ...state,
                isFetching: true,
                errors: null
            };
        case 'DONE_CORRECTION_CALCULATE_REQUEST':
            return {
                ...state,
                data: {
                    budgetArticles: action.payload.data ? action.payload.data.budgetArticles : state.data.budgetArticles,
                    headerProcurements: action.payload.data ? action.payload.data.headerProcurements : state.data.headerProcurements
                },
                errors: action.payload.errors,
                isFetching: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
