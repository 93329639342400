"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generatePaginationItems = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const store_1 = require("core/store");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const generatePaginationItems = (current, totalPages, handleChange) => {
    const iterate = current >= 4 ? current <= totalPages - 2 ? current : totalPages - 2 : 3;
    const items = [iterate - 2, iterate - 1, iterate, iterate + 1, iterate + 2];
    return items.map(item => {
        return ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)("a", { href: `/?page=${item}`, className: `datatable-pager-link datatable-pager-link-number ${item === current ? ' datatable-pager-link-active' : ''}`, onClick: e => handleChange(e, item), children: item }) }, 'paginateKey-' + item));
    });
};
exports.generatePaginationItems = generatePaginationItems;
const paginationStoreObject = (moduleName, perPage) => {
    const paginatedStore = JSON.parse(localStorage.getItem('paginated') || '""');
    if (!paginatedStore)
        return { [moduleName]: { perPage } };
    return {
        ...paginatedStore,
        [moduleName]: { perPage }
    };
};
const PaginationComponent = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    const handleChange = (e, page) => {
        e.preventDefault();
        if (!props.fetching)
            props.navigate(page);
    };
    const { count, currentPage, total, perPage, totalPages } = props.pagination || store_1.initialMeta.pagination;
    const disabled = props.fetching ? ' disabled' : '';
    return (total === 0
        ? null
        : (0, jsx_runtime_1.jsx)("div", { className: 'pagination-with-items-counter', children: (0, jsx_runtime_1.jsx)("div", { className: 'card mb-0', style: { border: 0 }, children: (0, jsx_runtime_1.jsx)("div", { className: 'card-body', style: { padding: '1.25rem' }, children: (0, jsx_runtime_1.jsx)("div", { className: 'datatable datatable-default', children: (0, jsx_runtime_1.jsxs)("div", { className: 'datatable-pager overflow-auto', children: [(0, jsx_runtime_1.jsxs)("ul", { className: 'datatable-pager-nav my-2 mb-sm-0"', children: [(0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)("a", { href: '/?page=1', title: '\u10E1\u10D0\u10EC\u10E7\u10D8\u10E1\u10D8', className: `datatable-pager-link datatable-pager-link-first${currentPage === 1 ? ' datatable-pager-link-disabled' : ''}`, onClick: e => handleChange(e, 1), children: (0, jsx_runtime_1.jsx)("i", { className: 'flaticon2-fast-back' }) }) }), (0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)("a", { href: `/?page=${currentPage - 1}`, title: '\u10EC\u10D8\u10DC\u10D0', className: `datatable-pager-link datatable-pager-link-prev${currentPage === 1 ? ' datatable-pager-link-disabled' : ''}`, onClick: e => handleChange(e, (currentPage - 1)), children: (0, jsx_runtime_1.jsx)("i", { className: 'flaticon2-back' }) }) }), totalPages <= 4 || (currentPage >= 4 && totalPages >= 6)
                                            ? ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)("a", { href: '/?page=1', className: `datatable-pager-link datatable-pager-link-number${currentPage === 1 ? ' datatable-pager-link-active' : ''}`, onClick: e => handleChange(e, 1), children: "1" }) })) : null, (totalPages <= 4 && totalPages > 1) || (currentPage >= 5 && totalPages >= 7)
                                            ? ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)("a", { className: `datatable-pager-link datatable-pager-link-number${currentPage === 2 ? ' datatable-pager-link-active' : ''}`, href: '/?page=2', onClick: e => handleChange(e, 2), children: "2" }) })) : null, currentPage >= 6 && totalPages > 7
                                            ? ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)("span", { className: 'datatable-pager-link datatable-pager-link-number', children: "..." }) })) : null, totalPages > 4
                                            ? (0, exports.generatePaginationItems)(currentPage, totalPages, handleChange) : null, currentPage + 4 < totalPages && totalPages > 7
                                            ? ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)("span", { className: 'datatable-pager-link datatable-pager-link-number', children: "..." }) })) : null, (totalPages === 3 || totalPages === 4) || (totalPages > 6 && currentPage < (totalPages - 3))
                                            ? ((0, jsx_runtime_1.jsx)("li", { className: `page-item${((totalPages === 3 || totalPages === 4) && currentPage === 3) || (currentPage > 3 && currentPage === (totalPages - 1))
                                                    ? ' active' : disabled}
                          `, children: ((totalPages === 3 || totalPages === 4) && currentPage === 3) || (currentPage > 3 && currentPage === (totalPages - 1)) ? ((0, jsx_runtime_1.jsx)("span", { className: 'page-link', children: totalPages === 3 ? totalPages : totalPages - 1 })) : ((0, jsx_runtime_1.jsx)("a", { href: `/?page=${totalPages === 3 ? totalPages : totalPages - 1}`, onClick: e => handleChange(e, (totalPages === 3 ? totalPages : totalPages - 1)), className: 'datatable-pager-link datatable-pager-link-number', children: totalPages === 3 ? totalPages : totalPages - 1 })) })) : null, totalPages === 4 || (totalPages > 5 && currentPage < (totalPages - 2))
                                            ? ((0, jsx_runtime_1.jsx)("li", { className: `page-item${currentPage !== totalPages ? disabled : ' active'}`, children: currentPage !== totalPages ? ((0, jsx_runtime_1.jsx)("a", { href: `/?page=${totalPages}`, onClick: e => handleChange(e, (totalPages)), className: 'datatable-pager-link datatable-pager-link-number', children: totalPages })) : (0, jsx_runtime_1.jsx)("span", { className: 'page-link', children: totalPages }) })) : null, (0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)("a", { href: `/?page=${currentPage + 1}`, onClick: e => handleChange(e, (currentPage + 1)), title: '\u10E8\u10D4\u10DB\u10D3\u10D4\u10D2\u10D8', className: `datatable-pager-link datatable-pager-link-next${currentPage === totalPages ? ' datatable-pager-link-disabled' : ''}`, children: (0, jsx_runtime_1.jsx)("i", { className: 'flaticon2-next' }) }) }), (0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)("a", { href: `/?page=${totalPages}`, onClick: e => handleChange(e, (totalPages)), title: '\u10EC\u10D8\u10DC\u10D0', className: `datatable-pager-link datatable-pager-link-last${currentPage === totalPages ? ' datatable-pager-link-disabled' : ''}`, children: (0, jsx_runtime_1.jsx)("i", { className: 'flaticon2-fast-next' }) }) })] }), (0, jsx_runtime_1.jsxs)("div", { className: 'datatable-pager-info my-2 mb-sm-0', children: [props.selectPerPage !== undefined
                                            ? ((0, jsx_runtime_1.jsxs)("select", { className: 'btn btn-sm dropdown-toggle btn-light mr-2', value: perPage, onChange: e => {
                                                    if (props.selectPerPage) {
                                                        props.selectPerPage(+e.target.value);
                                                        if (props.moduleName) {
                                                            const json = paginationStoreObject(props.moduleName, +e.target.value);
                                                            localStorage.setItem('paginated', JSON.stringify(json));
                                                        }
                                                    }
                                                }, children: [process.env.NODE_ENV === 'development' ? (0, jsx_runtime_1.jsx)("option", { value: '2', children: "2" }) : null, (0, jsx_runtime_1.jsx)("option", { value: '5', children: "5" }), (0, jsx_runtime_1.jsx)("option", { value: '10', children: "10" }), (0, jsx_runtime_1.jsx)("option", { value: '15', children: "15" }), (0, jsx_runtime_1.jsx)("option", { value: '25', children: "25" }), (0, jsx_runtime_1.jsx)("option", { value: '50', children: "50" }), (0, jsx_runtime_1.jsx)("option", { value: '100', children: "100" }), props.maxPerPage ? (0, jsx_runtime_1.jsx)("option", { value: props.maxPerPage, children: props.maxPerPage }) : null] })) : null, (0, jsx_runtime_1.jsxs)("span", { className: 'datatable-pager-detail', children: [translate('pagination_shown', 'ნაჩვენებია'), " ", currentPage === 1
                                                    ? '1 - ' + count
                                                    : ((currentPage - 1) * perPage + 1) + ' - ' + ((currentPage - 1) * perPage + count), " ", translate('pagination_record', 'ჩანაწერი'), " ", total, "-", translate('pagination_from', 'დან')] })] })] }) }) }) }) }));
};
exports.default = PaginationComponent;
