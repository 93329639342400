"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.editProcurementCorrectionValidation = void 0;
const types_1 = require("api/documents/purchases/procurementCorrections/types");
const types_2 = require("api/documents/purchases/procurements/types");
const validates_1 = require("core/helpers/validates");
const editProcurementCorrectionValidation = (values, translate) => {
    const letter = Number(values.correctionType.id) === types_1.ProcurementCorrectionType.LETTER;
    const formErrors = {
        correctionType: (0, validates_1.required)(values.correctionType, translate),
        letterNumber: letter ? (0, validates_1.required)(values.letterNumber, translate) : undefined,
        letterDate: letter ? (0, validates_1.required)(values.letterDate, translate) : undefined,
        signedDocumentNumber: values.status === types_2.ProcurementStatuses.SIGN_PROGRESS ? (0, validates_1.required)(values.signedDocumentNumber, translate) : undefined,
        dateOfConclusion: values.status === types_2.ProcurementStatuses.SIGN_PROGRESS ? (0, validates_1.required)(values.dateOfConclusion, translate) : undefined,
        deliveryDate: (0, validates_1.required)(values.deliveryDate, translate),
        company: (0, validates_1.required)(values.company, translate),
        procurementFilesError: values.isOther || values.templateType === types_2.TemplateTypes.CONSOLIDATED ? (0, validates_1.requiredNotEmptyArray)(values.procurementFile, translate) : undefined,
        companyAddress: (0, validates_1.required)(values.companyAddress, translate),
        companyBankAccount: (0, validates_1.required)(values.companyBankAccount, translate),
        companySignatory: (0, validates_1.required)(values.companySignatory, translate),
        type: (0, validates_1.required)(values.type, translate),
        templateType: (0, validates_1.required)(values.templateType, translate),
        contractValidityDate: (0, validates_1.required)(values.contractValidityDate, translate),
        fileErrors: values.status === types_2.ProcurementStatuses.SIGN_PROGRESS ? (0, validates_1.requiredNotEmptyArray)(values.files, translate) : undefined
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.editProcurementCorrectionValidation = editProcurementCorrectionValidation;
