"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMyStructuralAncestors = exports.getMyStructuralUnits = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/structuralUnitsUpgraded/parsers");
const getMyStructuralUnits = async (params) => {
    const response = await Api.get('user/structural-units', params);
    return (0, parsers_1.parseStructuralUnitsTree)(response);
};
exports.getMyStructuralUnits = getMyStructuralUnits;
const getMyStructuralAncestors = async (id) => {
    const response = await Api.get(`user/structural-units/ancestors/${id}`);
    return (0, parsers_1.parseAncestors)(response);
};
exports.getMyStructuralAncestors = getMyStructuralAncestors;
