"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const BackButton = () => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    return ((0, jsx_runtime_1.jsxs)("button", { className: 'btn btn-secondary m-1', type: 'button', onClick: () => navigate(-1), style: { marginRight: 5 }, children: [" ", translate('button_back', 'უკან')] }));
};
exports.default = BackButton;
