"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProcurementItemsV2Reducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialSubmissionVerifyItemsV2Store = {
    data: [],
    sumOnOtherPages: 0,
    isFetching: false,
    isLoading: false,
    meta: store_1.initialMeta,
    isUpdatingItemsList: false,
    errors: null
};
const useProcurementItemsV2Reducer = () => {
    return (0, react_1.useReducer)(procurementItemsReducer, initialSubmissionVerifyItemsV2Store);
};
exports.useProcurementItemsV2Reducer = useProcurementItemsV2Reducer;
const procurementItemsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_PROCUREMENT_ITEMS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_PROCUREMENT_ITEMS_REQUEST':
            return {
                ...state,
                data: action.payload.items.data || [],
                sumOnOtherPages: action.payload.items.data ? calculateSumOnOtherPages(action.payload.items.data, action.payload.procurementSumPrice) : state.sumOnOtherPages,
                meta: action.payload.items.meta || store_1.initialMeta,
                errors: action.payload.items.errors,
                isFetching: false
            };
        case 'SEND_ITEMS_LIST_UPDATE_REQUEST':
            return {
                ...state,
                errors: null,
                isUpdatingItemsList: true,
                isLoading: true
            };
        case 'DONE_ITEMS_LIST_UPDATE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isUpdatingItemsList: false,
                isLoading: state.isFetching
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        case 'UPDATE_PAGINATION_WHEN_DELETING':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        total: state.meta.pagination.total - action.payload,
                        count: state.meta.pagination.count - action.payload
                    }
                }
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        case 'CALCULATE_SUM_ON_OTHER_PAGE':
            return {
                ...state,
                sumOnOtherPages: calculateSumOnOtherPages(state.data, action.payload)
            };
        default:
            return state;
    }
};
const calculateSumOnOtherPages = (data, procurementSumPrice) => {
    if (isNaN(procurementSumPrice))
        return 0;
    const initialPageSum = data.reduce((sum, el) => {
        const currencyExchangeRate = el.currencyExchangeRate || 1;
        return sum + (el.price * el.quantity * (currencyExchangeRate / el.currencyRateQuantity));
    }, 0);
    return procurementSumPrice - initialPageSum;
};
