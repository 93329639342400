"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.correctionSubmissionOrLetterReducer = exports.useCorrectionSubmissionOrLetterReducer = exports.initialCorrectionState = void 0;
const react_1 = require("react");
const editProcurementCorrection_1 = require("./editProcurementCorrection");
exports.initialCorrectionState = {
    data: editProcurementCorrection_1.initialCorrectionFormValues,
    errors: null,
    isFetchingItems: false,
    isFetching: false
};
const useCorrectionSubmissionOrLetterReducer = () => {
    return (0, react_1.useReducer)(exports.correctionSubmissionOrLetterReducer, exports.initialCorrectionState);
};
exports.useCorrectionSubmissionOrLetterReducer = useCorrectionSubmissionOrLetterReducer;
const correctionSubmissionOrLetterReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CORRECTION_SUBMISSION_OR_LETTER_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true
            };
        case 'DONE_CORRECTION_SUBMISSION_OR_LETTER_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isFetching: false
            };
        case 'DELETE_SUBMISSION_TO_PROCUREMENT_CORRECTION':
            return {
                ...state,
                data: {
                    ...state.data,
                    submissions: state.data.submissions.filter((_el, index) => index !== action.payload.index),
                    reasons: action.payload.reasons
                }
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.correctionSubmissionOrLetterReducer = correctionSubmissionOrLetterReducer;
