"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addCompanyResidencyFile = exports.deleteAddress = exports.updateAddressStatus = exports.updateRequisiteStatus = exports.addBankAccount = exports.addAddress = exports.deleteSignatory = exports.addSignatory = exports.getCompaniesForSelect = exports.updateCompany = exports.getCompany = exports.deleteCompany = exports.getCompanies = exports.createCompany = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("./parsers");
const createCompany = async (params) => {
    const response = await Api.post('references/companies', params);
    return (0, parsers_1.parseCompany)(response);
};
exports.createCompany = createCompany;
const getCompanies = async (params) => {
    const response = await Api.get('references/companies', params);
    return (0, parsers_1.parseCompanies)(response);
};
exports.getCompanies = getCompanies;
const deleteCompany = async (id) => {
    return await Api.deleteItem('references/companies/' + id);
};
exports.deleteCompany = deleteCompany;
const getCompany = async (id) => {
    const response = await Api.get('references/companies/' + id);
    return (0, parsers_1.parseCompany)(response);
};
exports.getCompany = getCompany;
const updateCompany = async (params, id) => {
    const response = await Api.patch('references/companies/' + id, params);
    return (0, parsers_1.parseCompany)(response);
};
exports.updateCompany = updateCompany;
const getCompaniesForSelect = async (params) => {
    const response = await Api.get('references/companies', params);
    return (0, parsers_1.parseCompaniesForSelect)(response);
};
exports.getCompaniesForSelect = getCompaniesForSelect;
const addSignatory = async (params, id) => {
    const response = await Api.post('references/companies/attach-signatories/' + id, params);
    return (0, parsers_1.parseCompanySignatories)(response);
};
exports.addSignatory = addSignatory;
const deleteSignatory = async (id) => {
    return await Api.deleteItem('references/companies/detach-signatories/' + id);
};
exports.deleteSignatory = deleteSignatory;
const addAddress = async (params, id) => {
    const response = await Api.post('references/companies/attach-addresses/' + id, params);
    return (0, parsers_1.parseCompanyAddresses)(response);
};
exports.addAddress = addAddress;
const addBankAccount = async (params, id) => {
    const response = await Api.post('references/companies/attach-bank-accounts/' + id, params);
    return (0, parsers_1.parseCompanyBankAccount)(response);
};
exports.addBankAccount = addBankAccount;
const updateRequisiteStatus = async (id) => {
    const response = await Api.patch(`references/companies/bank-account/change-status/${id}`, {});
    return (0, parsers_1.parseCompanyBankAccount)(response);
};
exports.updateRequisiteStatus = updateRequisiteStatus;
const updateAddressStatus = async (id) => {
    const response = await Api.patch(`references/companies/addresses/change-status/${id}`, {});
    return (0, parsers_1.parseCompanyAddresses)(response);
};
exports.updateAddressStatus = updateAddressStatus;
const deleteAddress = async (id) => {
    return await Api.deleteItem(`references/companies/addresses/${id}`);
};
exports.deleteAddress = deleteAddress;
const addCompanyResidencyFile = async (params, id) => {
    const response = await Api.post(`references/companies/${id}/attach-residency-file`, params);
    return (0, parsers_1.parseCompanyResidencyFile)(response);
};
exports.addCompanyResidencyFile = addCompanyResidencyFile;
