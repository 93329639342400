"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("src/api");
const StructuralUnits_1 = require("src/modules/references/structuralUnitsUpgraded/store/StructuralUnits");
const react_1 = require("react");
const useStructuralUnits = (props) => {
    const [state, dispatch] = (0, StructuralUnits_1.useStructuralUnitsReducer)();
    const [firstRendering, setFirstRendering] = (0, react_1.useState)(false);
    const isMounted = (0, react_1.useRef)(false);
    const getStructuralUnits = async (params) => {
        dispatch({ type: 'SEND_STRUCTURAL_UNITS_REQUEST' });
        params.sort = '+sort,-id';
        const structuralUnits = await api_1.MyStrctureApi.getMyStructuralUnits(params);
        if (isMounted?.current) {
            dispatch({ type: 'DONE_STRUCTURAL_UNITS_REQUEST', payload: structuralUnits });
        }
    };
    const getChildren = async (parentId, childrenFetched, parentIdentifier) => {
        if (childrenFetched) {
            dispatch({ type: 'OPEN_FETCHED_CHILDREN', payload: { parentIdentifier } });
        }
        else {
            dispatch({ type: 'SEND_CHILDREN_REQUEST', payload: { parentId, parentIdentifier } });
            const structuralUnits = await api_1.MyStrctureApi.getMyStructuralUnits({ filters: { parentId }, perPage: 1000, sort: '+sort,-id' });
            dispatch({ type: 'DONE_CHILDREN_REQUEST', payload: { parentId, structuralUnits, parentIdentifier } });
        }
    };
    const closeStructuralUnit = (parentIdentifier) => {
        dispatch({ type: 'CLOSE_STRUCTURAL_UNIT', payload: { parentIdentifier } });
    };
    const getAncestors = async (id) => {
        return await api_1.MyStrctureApi.getMyStructuralAncestors(id);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        if (props.activeAccountStructuralUnitId) {
            setFirstRendering(true);
            getStructuralUnits({ filters: { type: props.type, parentId: props.activeAccountStructuralUnitId } }).then(() => {
                if (isMounted?.current)
                    setFirstRendering(false);
            });
        }
        return () => { isMounted.current = false; };
    }, [props.activeAccountStructuralUnitId]);
    return {
        state,
        firstRendering,
        getChildren,
        closeStructuralUnit,
        getAncestors
    };
};
exports.default = useStructuralUnits;
